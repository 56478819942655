import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";
import SubNavMenu from "./SubNavMenu";
import { useNavigate } from "react-router-dom";

export default function NavMenu(props) {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "navmenu" });

  const navItems = [
    { title: t("overview"), href: "#" },
    { title: t("ir"), href: "#" },
    { title: t("contact"), href: "#" },
  ];

  const [isShow, setIsShow] = useState(false);

  const [navShow, setNavShow] = useState(false);

  const [navCat, setNavCat] = useState("");

  const nav = useNavigate();

  const onNavClick = (cat) => {
    if (cat === 2) {
      nav("/contactus");
      setIsShow(false);
    }
    let tran = cat === 0 ? "overview" : cat === 1 ? "ir" : "";
    [0, 1].map((i) => {
      if (i === cat) {
        document.getElementById(`arrow${i}`).classList.add("rotate-180");
        document.getElementById(`arrowM${i}`).classList.add("rotate-180");
        document.getElementById(`subnav-${i}`).classList.remove("hidden");
      } else {
        document.getElementById(`arrow${i}`).classList.remove("rotate-180");
        document.getElementById(`arrowM${i}`).classList.remove("rotate-180");
        document.getElementById(`subnav-${i}`).classList.add("hidden");
      }
    });
    if (navCat == tran || tran == "") {
      setNavShow(false);
      setNavCat("");
      document.getElementById(`arrow${cat}`).classList.remove("rotate-180");
      document.getElementById(`arrowM${cat}`).classList.remove("rotate-180");
      document.getElementById(`subnav-${cat}`).classList.add("hidden");
    } else {
      setNavCat(tran);
      setNavShow(true);
    }
  };

  const ulRef = useRef(null);

  const [navX, setNavX] = useState(0);

  const updateNavX = () => {
    if (ulRef.current) {
      const ulPosition = ulRef.current.getBoundingClientRect();
      if (window.innerWidth < 1536) {
        setNavX(ulPosition.x);
      } else {
        setNavX(ulPosition.x - (window.innerWidth - 1536) / 2);
      }
    }
  };

  useEffect(() => {
    updateNavX(); // Initial check

    window.addEventListener("resize", updateNavX);
    return () => {
      window.removeEventListener("resize", updateNavX);
    };
  }, []);

  useEffect(() => {
    updateNavX();
  }, [i18n.language]);

  useEffect(() => {
    //console.log("navCat", navCat);
  }, [navCat]);

  return (
    <header className="fixed z-[9999] h-fit w-full 2xl:container">
      <nav className="relative z-[100] hidden h-[80px] w-full items-center justify-between overflow-hidden bg-white/75 font-medium text-gray-500 shadow-md md:flex md:px-12 lg:px-16 xl:px-32">
        <div className="h-18 w-32">
          <a href="/">
            <img
              src={require("../img/DTEIT Buy-back.png")}
              className="object-contain"
            />
          </a>
        </div>
        <div className="flex items-center justify-between py-2">
          <ul className="flex items-center justify-between" ref={ulRef}>
            {navItems.map((i, x) => (
              <li
                key={x}
                className={`flex items-center justify-between gap-3 px-[12px] hover:rounded-full hover:bg-gold hover:text-white`}
                onClick={() => onNavClick(x)}
              >
                <div>{i.title}</div>
                <MdKeyboardArrowDown
                  id={`arrow${x}`}
                  className={`${x == 2 ? "hidden" : ""} `}
                />
              </li>
            ))}
          </ul>
          <div className="rounded-full bg-gold p-1 font-Petrona text-white">
            <ul className="flex items-center justify-between gap-1">
              <li
                className={`flex h-[32px] w-[32px] items-center justify-center rounded-full ${
                  i18n.language === "en"
                    ? "border bg-white text-gold"
                    : "hover:text-lg hover:font-bold"
                }`}
              >
                <div
                  onClick={() => {
                    i18n.changeLanguage("en");
                    localStorage.setItem("i18nextLng", "en");
                  }}
                  className={`cursor-pointer`}
                >
                  EN
                </div>
              </li>
              <div className="h-[30px] border-r"></div>
              <li
                className={`flex h-[32px] w-[32px] items-center justify-center rounded-full ${
                  i18n.language === "th"
                    ? "border bg-white text-gold"
                    : "hover:text-lg hover:font-bold"
                }`}
              >
                <div
                  onClick={() => {
                    i18n.changeLanguage("th");
                    localStorage.setItem("i18nextLng", "th");
                  }}
                  className={`cursor-pointer`}
                >
                  TH
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="h-0 max-md:hidden">
        <div
          className={`absolute left-0 top-0 z-[98] h-screen w-full ${navShow ? "block" : "hidden"}`}
          onClick={() => onNavClick("")}
        ></div>
        <div
          className={`relative z-[99] transition-all duration-300 ${navShow ? "translate-y-0 opacity-100" : "-translate-y-[100%] opacity-0"} h-fit w-[350px] bg-white p-6 shadow-lg`}
          style={{
            left:
              navCat != "ir"
                ? navX
                : navX + (i18n.language == "en" ? 200 : 160),
          }}
        >
          <SubNavMenu main={navCat} />
        </div>
      </div>
      {/* -------------- Mobile ---------------- */}
      <nav className="w-full md:hidden">
        <div className="relative z-10 flex h-[80px] w-full items-center justify-between bg-white px-5 shadow-md">
          <div className="h-12 w-24">
            <a href="/">
              <img
                src={require("../img/DTEIT Buy-back.png")}
                className="object-contain"
              />
            </a>
          </div>
          <div
            className="flex h-[32px] w-[32px] cursor-pointer flex-col justify-around"
            onClick={() => setIsShow(!isShow)}
          >
            <div
              className={`rounded-full border-b-[3px] border-[#2C348B] transition-all duration-300 ${
                isShow ? "translate-y-[11px] rotate-45" : ""
              }`}
            ></div>
            <div
              className={`rounded-full border-b-[3px] border-[#2C348B] transition-all duration-300 ${
                isShow ? "border-transparent" : ""
              }`}
            ></div>
            <div
              className={`rounded-full border-b-[3px] border-[#2C348B] transition-all duration-300 ${
                isShow ? "w-full -translate-y-[11px] -rotate-45" : "w-3/4"
              }`}
            ></div>
          </div>
        </div>
        <div
          className={`w-full bg-white shadow-md transition duration-300 max-sm:h-[calc(100vh-70px)] sm:w-[400px] ${
            isShow ? "" : "-translate-y-[2000px]"
          } absolute right-0 top-[72px] z-0`}
        >
          <div className="mb-5 max-h-[calc(100vh-140px)] overflow-y-auto px-5">
            <ul className="flex flex-col">
              {navItems.map((i, x) => (
                <div className="first:mt-8">
                  <li
                    key={x}
                    className={`rounded-tr-xl border-b px-[22px] pt-8 hover:bg-gold hover:text-white`}
                    onClick={() => onNavClick(x)}
                  >
                    <div className="flex items-center gap-2">
                      {i.title}
                      <MdKeyboardArrowDown
                        id={`arrowM${x}`}
                        className={`${x == 2 ? "hidden" : ""}`}
                      />
                    </div>
                  </li>
                  {x != 2 && (
                    <div id={`subnav-${x}`} className={`mt-5 hidden pl-10`}>
                      <SubNavMenu main={navCat} />
                    </div>
                  )}
                </div>
              ))}
            </ul>
          </div>

          <div className="mb-5 mr-4 flex justify-end font-Petrona">
            <div className="w-fit rounded-full bg-gold p-1 text-white">
              <ul className="flex items-center justify-between gap-1">
                <li
                  className={`flex h-[32px] w-[32px] items-center justify-center rounded-full ${
                    i18n.language === "en"
                      ? "border bg-white text-gold"
                      : "hover:text-lg hover:font-bold"
                  }`}
                >
                  <div
                    onClick={() => {
                      i18n.changeLanguage("en");
                      localStorage.setItem("i18nextLng", "en");
                    }}
                    className={`cursor-pointer`}
                  >
                    EN
                  </div>
                </li>
                <div className="h-[30px] border-r"></div>
                <li
                  className={`flex h-[32px] w-[32px] items-center justify-center rounded-full ${
                    i18n.language === "th"
                      ? "border bg-white text-gold"
                      : "hover:text-lg hover:font-bold"
                  }`}
                >
                  <div
                    onClick={() => {
                      i18n.changeLanguage("th");
                      localStorage.setItem("i18nextLng", "th");
                    }}
                    className={`cursor-pointer`}
                  >
                    TH
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
