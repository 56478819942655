import React from "react";
import { useTranslation } from "react-i18next";

import { TermTH, TermEN } from "./termText";

export default function Term(props) {
  const { i18n } = useTranslation();

  return (
    <section className="term-condition px-8 py-12 sm:px-16 lg:px-32 lg:py-20">
      {i18n.language == "en" ? <TermEN /> : <TermTH />}
    </section>
  );
}
