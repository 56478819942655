import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import * as d3 from "d3";
import { t } from "i18next";

export default function RevChart(props) {
  const [pData, setPdata] = useState([]);

  useEffect(() => {
    setPdata(props.data);
  }, [props.data]);

  const [data, setData] = useState([]);

  const options = {
    title: "Revenue Performance",
    legend: {
      position: "bottom",
    },

    series: {
      0: { type: "bars", targetAxisIndex: 0, color: "#13216A" },
      1: {
        type: "line",
        targetAxisIndex: 1,
        color: "#bead8f",
      },
    },
    vAxes: {
      0: { title: "Revenue", format: "long" },
      1: { title: "EBITDA", format: "long" },
    },
    hAxis: {
      title: props.timespan == "all" ? "Year" : "Month",
    },
    vAxis: {
      minorGuildlines: null,
    },
    height: 400,
    tooltip: {
      isHtml: true,
      trigger: "both",
    },
  };

  const sumData = () => {
    let dat = [
      [
        props.timespan == "all" ? "year" : "month",
        "Revenue",
        { type: "string", role: "tooltip", p: { html: true } },
        "EBITDA",
        { type: "string", role: "tooltip", p: { html: true } },
      ],
    ];
    pData.map((d, i) => {
      dat.push([
        props.timespan == "all" ? d.year.toString() : d.month,
        d.rev,
        "<div style='padding:5px;color:black;'><strong>" +
          (props.timespan == "all" ? d.year : d.month) +
          "</strong></br><p style='display:flex;'>Revenue:<strong style='margin-left:4px'>" +
          d.rev.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
          "</strong></p></div>",
        d.ebitda,

        "<div style='padding:5px;color:black;'><strong>" +
          (props.timespan == "all" ? d.year : d.month) +
          "</strong></br><p style='display:flex;'>EBITDA:<strong style='margin-left:4px'>" +
          d.ebitda.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
          "</strong></p></div>",
      ]);
    });
    //console.log(dat);
    setData(dat);
  };

  useEffect(() => {
    sumData();
  }, [pData]);

  return (
    <Chart
      chartType="ComboChart"
      data={data}
      options={options}
      width={"100%"}
    />
  );
}
