import Overview from "./corporate/overview/overview";
import Structure from "./corporate/structure/structure";
import Policy from "./corporate/policy/policy";
import Manager from "./management/manager/manager";
import RStructure from "./management/structure/rstructure";
import Board from "./management/board/board";
import Lease from "./management/lease/lease";
import Trustee from "./management/trustee/trustee";
import Chinatown from "./investment/asset/chinatown";
import Sathorn from "./investment/asset/sathorn";
import Performace from "./investment/performance/performance";
import Contact from "./contact/contact";
import Cookie from "./term/cookie";
import Privacy from "./term/privacy";
import Term from "./term/term";

const AppRoutes = [
  {
    path: "/corporate",
    element: <Overview />,
  },
  {
    path: "/corporate/reit",
    element: <Overview />,
  },
  {
    path: "/corporate/reit/about",
    element: <Overview />,
  },
  {
    path: "/corporate/reit/structure",
    element: <Structure />,
  },
  {
    path: "/corporate/reit/investmentpolicy",
    element: <Policy />,
  },
  {
    path: "/corporate/management",
    element: <Manager />,
  },
  {
    path: "/corporate/management/manager",
    element: <Manager />,
  },
  {
    path: "/corporate/management/reitstructure",
    element: <RStructure />,
  },
  {
    path: "/corporate/management/board",
    element: <Board />,
  },
  {
    path: "/corporate/management/lease",
    element: <Lease />,
  },
  {
    path: "/corporate/management/trustee",
    element: <Trustee />,
  },
  {
    path: "/corporate/investment/chinatown",
    element: <Chinatown />,
  },
  {
    path: "/corporate/investment/sathorn",
    element: <Sathorn />,
  },
  {
    path: "/corporate/investment/performance",
    element: <Performace />,
  },
  {
    path: "/corporate/investment",
    element: <Performace />,
  },
  {
    path: "/contactus",
    element: <Contact />,
  },
  {
    path: "/cookie",
    element: <Cookie />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/term",
    element: <Term />,
  },
];

export default AppRoutes;
