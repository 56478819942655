import FAQ from "./resource/faq";
import ContactPoint from "./resource/contactpoint";
import NAV from "./finance/nav/nav";
import News from "./publication/news/news";

const AppRoutesIR = [
  {
    path: "/ir",
    element: <NAV />,
  },
  {
    path: "/ir/resources",
    element: <FAQ />,
  },
  {
    path: "/ir/resources/faq",
    element: <FAQ />,
  },
  {
    path: "/ir/resources/contactinfo",
    element: <ContactPoint />,
  },
  {
    path: "/ir/finance",
    element: <NAV />,
  },
  {
    path: "/ir/finance/nav",
    element: <NAV />,
  },
  {
    path: "/ir/publications",
    element: <News />,
  },
  {
    path: "/ir/publications/news",
    element: <News />,
  },
];

export default AppRoutesIR;
