import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "footer" });
  const { t: tN } = useTranslation("translation", { keyPrefix: "navmenu" });

  const navItems = [
    { title: tN("home"), href: "/" },
    { title: tN("condition"), href: "/term" },
    { title: tN("cookie"), href: "/cookie" },
    { title: tN("privacy"), href: "/privacy" },
  ];
  return (
    <section
      id="footer"
      className="bg-gray-100 px-10 pt-10 md:px-20 lg:px-32 lg:pt-20"
    >
      <div className="mb-10 grid gap-8 sm:gap-12 md:mb-24 lg:grid-cols-[2fr_1fr_2fr] lg:gap-24">
        <div className="sm:max-lg:flex">
          <img
            src={require("../img/DTEIT Buy-back.png")}
            className="w-40 object-contain md:h-32 md:w-60"
          />
          <p className="mt-4 sm:mt-8">{t("invest")}</p>
        </div>

        <div>
          <h4 className="mb-8 font-Petrona text-4xl text-blueD max-md:hidden">
            DREITBB
          </h4>
          <ul>
            {navItems.map((i, x) => (
              <li key={x} className="mb-2">
                <a href={i.href}>{i.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h5 className="mb-4 text-2xl lg:mb-8">{t("company")}</h5>
          <p>{t("addr1")}</p>
          <p className="py-1 text-lg text-gold">{t("addr2")}</p>
        </div>
      </div>
      <div className="text-[#8d8d8d]">
        Copyright &copy; Dusit Thani Freehold and Leasehold Real Estate
        Investment Trust with Buy-Back Condition
      </div>
    </section>
  );
}
