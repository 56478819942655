import React, { useEffect } from "react";
import Hero from "./hero";
import About from "./about";
import Hilight from "./hilight";
import Asset from "./asset";

export default function Home(props) {
  useEffect(() => {
    document.title = "DREIT Buy Back - Home";
  }, []);

  return (
    <section id="home-page">
      <Hero />
      <About />
      <Hilight />
      <Asset />
    </section>
  );
}
