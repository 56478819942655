import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";

import CorrectDate from "../../components/correctDate";

import { LuDownload } from "react-icons/lu";

export default function News(props) {
  const { t, i18n } = useTranslation("ir", { keyPrefix: "publication.news" });
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "DREIT Buy Back - News Release";
  }, []);

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_nrl;

  const fetchData = async () => {
    try {
      const response = await Axios.get(url + "/getNews/" + i18n.language);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <section
      id="newsrelease"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-5 w-full sm:mb-10 lg:mb-24">
        <h1 className="text-3xl font-semibold text-gold">{t("title")}</h1>
      </div>
      <div className="py-2 lg:p-4">
        <table className="w-full table-fixed border-separate border-spacing-x-2">
          <thead>
            <tr className="font-medium text-gold">
              <th className="w-[25%] border-b-2 border-[#F2E5CF] p-2 text-left sm:w-[20%] lg:w-[15%]">
                {t("date")}
              </th>
              <th className="border-b-2 border-[#F2E5CF] p-2 text-left">
                {t("ntitle")}
              </th>
              <th className="w-[25%] border-b-2 border-[#F2E5CF] p-2 text-right sm:w-[20%] lg:w-[15%]">
                {t("download")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr className="border-b-2 border-[#F2E5CF]">
                <td className="p-2">
                  {CorrectDate(d.releaseDate, "f", "", i18n.language)}
                </td>
                <td className="p-2 text-left">{d.title}</td>
                <td className="p-2">
                  <a
                    href={`https://dreitbb.blob.core.windows.net/news/${d.file}`}
                    download={true}
                    target="_blank"
                  >
                    <div className="flex w-full items-center justify-end gap-1 text-blueD hover:font-semibold">
                      <LuDownload />
                      {t("file")}
                    </div>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
