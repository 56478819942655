import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";

export default function NationChart(props) {
  const { i18n } = useTranslation();
  const [pData, setPdata] = useState([]);

  useEffect(() => {
    setPdata(props.data);
  }, [props.data]);

  const [data, setData] = useState([]);

  const options = {
    title:
      i18n.language == "en"
        ? "Guest Nationality Ratio"
        : "อัตราส่วนสัญชาติผู้เข้าพัก",
    legend: {
      position: "right",
    },

    height: 400,
    tooltip: {
      isHtml: true,
      trigger: "both",
    },
  };

  const sumData = () => {
    let dat = [
      [
        i18n.language == "en" ? "Nation" : "สัญชาติ",
        i18n.language == "en" ? "Ratio" : "สัดส่วน",
        { type: "string", role: "tooltip", p: { html: true } },
      ],
    ];
    pData.map((d, i) => {
      dat.push([
        i18n.language == "en" ? d.nation : d.nationTh,
        d.ratio,
        `<div><strong>${i18n.language == "en" ? d.nation : d.nationTh}</strong></br><span style="display:flex;">${i18n.language == "en" ? "Ratio " : "สัดส่วน: "}&nbsp;<strong>${d.ratio.toFixed(2)}%</strong></span></div>`,
      ]);
    });
    setData(dat);
  };

  useEffect(() => {
    sumData();
  }, [pData]);

  return (
    <Chart chartType="PieChart" data={data} options={options} width={"100%"} />
  );
}
