const Monthname = [
  { en: "January", th: "มกราคม", sen: "Jan", sth: "ม.ค." },
  { en: "February", th: "กุมภาพันธ์", sen: "Feb", sth: "ก.พ." },
  { en: "March", th: "มีนาคม", sen: "Mar", sth: "มี.ค." },
  { en: "April", th: "เมษายน", sen: "Apr", sth: "เม.ย." },
  { en: "May", th: "พฤษภาคม", sen: "May", sth: "พ.ค." },
  { en: "June", th: "มิถุนายน", sen: "Jun", sth: "มิ.ย." },
  { en: "July", th: "กรกฎาคม", sen: "Jul", sth: "ก.ค." },
  { en: "August", th: "สิงหาคม", sen: "Aug", sth: "ส.ค." },
  { en: "September", th: "กันยายน", sen: "Sep", sth: "ก.ย." },
  { en: "October", th: "ตุลาคม", sen: "Oct", sth: "ต.ค." },
  { en: "November", th: "พฤศจิกายน", sen: "Nov", sth: "พ.ย." },
  { en: "December", th: "ธันวาคม", sen: "Dec", sth: "ธ.ค." },
];

export default Monthname;
