import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function RStructure() {
  const { t } = useTranslation("management", { keyPrefix: "structure" });

  useEffect(() => {
    document.title = "DREIT Buy Back - Reit Structure";
  }, []);
  return (
    <section
      id="REITstructure"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-10 w-full text-center sm:mb-20 lg:mb-32">
        <h1 className="text-3xl font-semibold">{t("title")}</h1>
        <img
          src={require("./img/structure.png")}
          alt="Reit structure"
          className="mx-auto my-4 sm:my-7 lg:my-14"
        />
      </div>

      {/*------------------------------------*/}
    </section>
  );
}
