import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AppRoutesIR from "./AppRoutesIR";
import Layout from "./components/Layout";
import "./custom.css";
import Home from "./Home/Home";
import Breadcrum from "./components/breadcrum";

export default function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route element={<Breadcrum />}>
          {AppRoutes.map((route, i) => (
            <Route key={i} path={route.path} element={route.element} />
          ))}
          {AppRoutesIR.map((route, i) => (
            <Route key={i} path={route.path} element={route.element} />
          ))}
        </Route>
      </Route>
    </Routes>
  );
}
