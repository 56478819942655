export const PrivacyEN = () => (
  <div>
    <h2 className="mb-2 w-fit whitespace-nowrap text-xl font-medium text-gold md:text-2xl">
      Privacy Policy
    </h2>
    <p>
      We, Dusit Thani Properties REIT Company Limited (“Company”, “we”, “us”,
      “our”), as a controller of Personal Data, is bound by Personal Data
      Protection Act B.E. 2562 (2019) (“PDPA”). We are committed to comply with
      PDPA to protect your persona data. This policy describes how we process or
      perform on your Personal Data such as collection, recording, organization,
      storage, alteration, use, disclosure, forward, and dissemination as
      follows:
    </p>
    <br />
    <div className="md:pl-4">
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          Collection of Personal Data
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        We may collect Personal Data directly and indirectly from data subject,
        his representative or other related persons, through website, business
        partners, call center and online communication channels as well as other
        reliable sources such as association, governmental organization,
        governmental body, private organization, etc.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          Types of Personal Data Collected
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        We may collect or obtain the following types of information which may
        include your Personal Data directly or indirectly from you or other
        sources. The specific type of Personal Data collected will depend on the
        context of your interactions with us.
      </p>
      <br />
      <ul className="list-inside list-disc">
        <li>
          Your personal data as the registered unitholders, including full name,
          nationality, occupation, current address, number of units being held,
          e-mail, phone number and your bank account information
        </li>
        <li>
          Your personal data as the interested investors who contacts the
          Company via investor relations channels, e.g., full name, contact
          information, including place of work, work address and work title
        </li>
        <li>Your inquiries, correspondence, or email subscription status</li>
        <li>
          Details of anyone Authorized to act on your behalf if applicable
        </li>
        <li>
          Record, store and use of any images, video and audio data when you
          have investor/analyst meeting, site visit or open house with us
          including press conference
        </li>
        <li>
          Internet protocol (IP) address, browser type and version, time zone
          setting and location, browser plug-in types and versions, operating
          system and platform and other technology on the devices you use to
          access our website
        </li>
      </ul>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          Retention Period
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        We will retain your Personal Data as long as it is necessary for the
        purpose of data processing. After that, We will erase and destroy your
        Personal Data except as may be required, by applicable laws, or for
        protection of our interest. In general, your Personal Data will be kept
        for a maximum period of 10 years or otherwise longer if it is
        specifically provided by law.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          Purposes of Use and Disclosure
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        We will use and disclose your Personal Data for the following purposes:
      </p>
      <br />
      <ul className="list-inside list-disc">
        <li>
          Deliver meeting invitation and other notices to as the unit holders,
          debenture holders, investors, analysts as may be required under the
          relevant laws, for instance, the invitation to the general annual
          unitholders' meeting, investor/analysts meeting or the submission of
          the annual business report/media releases, newsletters of the Company
          to you
        </li>
        <li>
          Verify your identity or the rights of your proxy who will be attending
          the shareholders' meeting
        </li>
        <li>Verify your right to transfer your shares</li>
        <li>Allocate and pay distribution to you as the unitholder</li>
        <li>
          Perform accounting and bookkeeping as required under the applicable
          laws
        </li>
        <li>Manage and respond to your inquiries, feedback, complaints</li>
        <li>
          Prepare the annual report that the Company shall be obliged under the
          applicable laws to prepare that may include your personal data as the
          majority shareholders and to deliver those reports to the relevant
          government authorities or the public pursuant to the terms and
          conditions prescribed under the applicable laws
        </li>
        <li>Maintain network and data security</li>
        <li>
          Comply with applicable laws that require us to process certain
          Personal Information
        </li>
      </ul>
      <br />
      <p>
        In the case where the Company appoints third party service provider(s)
        to process your Personal Data, we will process your Personal Data for
        the purposes of performing Company's contractual obligations, compliance
        with legal obligations, based on Company's legitimate interests or based
        on your consent (if necessary). We will not disclose or permit the
        disclosure or make available your Personal Data other than in accordance
        with the relevant ground unless a prior consent of data subject has been
        given to the Company or PDPA allows us to do so without obtaining
        consent from data subject.
      </p>
      <br />
      <p>
        If any data subject could not share Personal Data necessary for the
        Company to process Personal Data based on the ground specified above, we
        will not be able to perform its contractual obligations with such
        subject matter nor able to comply with legal obligations. We will
        process Personal Data in strict compliance with applicable laws. In case
        where you have given a consent to any data processing activity, you may
        withdraw your consent at any time.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          Disclosure
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        We will not disclose your Personal Data without any legal basis. Your
        data may be disclosed or transferred to governmental organizations,
        governmental bodies or third parties including:
      </p>
      <br />
      <p>
        <font className="font-medium">Service Providers/ Vendor</font> : We may
        share your Personal Information to our third-party service providers,
        subcontractors and other associated organizations such as Thailand
        Securities Depository Company Limited as the Company's registrar for the
        purposes of carrying out a task in accordance with any obligations that
        the Company may have with you. However, when we share your Personal
        Information with third parties, we disclose only the Personal
        Information that is necessary to deliver the service and we have a
        contract in place that requires them to keep your Personal Information
        secure and only for the duration it is required to carry out that
        specific task. In some cases, they will be acting as a data controller
        of your data and therefore we advise you to read their privacy notice.
        Such third-party companies may fall under the following categories: tax
        consultants, financial advisors, content protection, etc.
      </p>
      <p>
        <font className="font-medium">Government Authorities</font> : We may
        share Personal Information to relevant government authorities to fulfill
        any legal obligations and/or requirement we may have.
      </p>
      <br />
      <p>
        We will disclose your Personal Data to the recipient outside of Thailand
        only where it is necessary to perform any obligations that the Company
        may have with you and only when it is permitted by PDPA or other
        applicable laws.
      </p>
      <br />
      <p>
        In the event where the Company is required to disclose your Personal
        Data to a third party, we will follow appropriate procedures to ensure
        that the third party will properly handle your Personal Data in order to
        prevent data loss, unauthorized access, improper use, modification,
        disclosure or processing.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          Data Security Measure
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        We adopt the high-standard security system in both technology and
        procedures to prevent any possible data theft. We implement various
        measures to protect its computer system (such as Network Firewall,
        Intrusion Detection, Threat Prevention, URL Filtering, Application
        Control, Email Security, Server and Endpoint Protection, Secure Socket
        Layer, Cookies), through substantial investments, effort and human
        resources as to ensure that we achieve high-standard measures, and your
        personal data remains safe.
      </p>
      <br />
      <p>
        Although we make its best efforts to protect personal data with our
        technical mechanism along with the management by our personnel to
        control access and keep personal data against unauthorized access, wet
        cannot always guarantee the security and confidentiality of personal
        data from every incident that may arise, such as virus threat and
        unauthorized access. A data subject should regularly keep up with
        technology news, install personal firewall software to prevent his
        computer from threat or data theft.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          Right of Data Subject
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        In exercising any right under this Clause, data subject shall comply
        with criteria and procedures specified in this Clause of this document.
        However, the rights specified in this are subject to change as the
        relevant law may be amended from time to time by the government. We will
        inform you about the changes.
      </p>
      <br />
      <ul className="list-inside list-disc">
        <li>
          <font className="font-medium">Right to be notified</font> : if we wish
          to collect, store, use or disclose your Personal Data in any manner
          beyond the intended purposes or your given consent, we will notify
          and/or seek your prior consent with respect to such additional scope.
        </li>
        <li>
          <font className="font-medium">
            Right to Access to your Personal Data
          </font>
          : You may request for a copy of your Personal Data and request to
          disclose about the source of your Personal Data.
        </li>
        <li>
          <font className="font-medium">
            Rectification of the Personal Data
          </font>
          : To ensure that your Personal Data is accurate, up to date, complete,
          and not misleading, you may file a request to rectify any of your
          Personal Data that has been changed.
        </li>
        <li>
          <font className="font-medium">Right to data portability</font>: In
          case where it is technically available, you may request to receive
          your Personal Data in a commonly used or readable by the automatic
          device or to automatically transfer.
        </li>
        <li>
          <font className="font-medium">
            Right to erasure of your Personal Data
          </font>
          : You may request to erase or make your Personal Data pseudonymized
          under any of the following circumstances: (a) your Personal Data is no
          longer needed to be collected, stored, used or disclosed for the
          intended purposes, (b) you withdraw your consent for your Personal
          Data to be collected, stored, used or disclosed and Dusit no longer
          has any legal right to process your Personal Data for the intended
          purposes, (c) you object to Company's processing of your Personal
          Data, or (d) your Personal Data was processed in contravention of the
          PDPA.
        </li>
        <li>
          <font className="font-medium">
            Request to suspend the use of your Personal Data
          </font>
          : You may request the Company to suspend its use of your Personal Data
          in any of the following events:
          <ol className="list-inside list-decimal pl-8">
            <li>
              when the Company is in the process of verifying certain
              information for the purpose of rectifying, updating, completing or
              avoiding any misleading about your Personal Data upon your
              request;
            </li>
            <li>
              when your Personal Data is to be erased but you instead request to
              suspend its use;
            </li>
            <li>
              when it is no longer necessary to store your Personal Data, but
              you request the Company to continue the storage of your Personal
              Data for establishing legal claims, legal compliance, exercise of
              legal rights or defenses; or
            </li>
            <li>
              when the Company is in the process of verifying its legitimate
              rights in its data collection or processing for purposes specified
              by law.
            </li>
          </ol>
        </li>
        <li>
          <font className="font-medium">
            Right to object the processing of Personal Data
          </font>
          : You may object to the collection, storage, use or disclosure of your
          Personal Data in any of the following events:
          <ol className="list-inside list-decimal pl-8">
            <li>
              In case where your Personal Data was collected by the Company for
              the purpose of (a) Company's compliance with a governmental order
              or (b) any legitimate interest of Company or other legal entity;
            </li>
            <li>
              In case where the Company has processed your Personal Data for the
              purpose of direct marketing; and
            </li>
            <li>
              In case where the Company has processed your Personal Data for any
              research purposes as specified in relevant laws, including for
              statistical purpose.
            </li>
          </ol>
        </li>
        <li>
          <font className="font-medium">Right to withdraw consent</font>: You
          may withdraw your consent at any time. Your withdrawal will not have
          any effect on Company's previous data processing. If your withdrawal
          will affect any part of your Personal Data, we will notify you of such
          effect at the time you make such withdrawal.
        </li>
      </ul>
      <br />
      <p>
        However, we may deny your request to withdraw consent if the processing
        is for the purpose of, or for complying with, applicable law or court
        order, the withdrawal may adversely affect and harm the rights and
        freedom of the data subject himself or other people, the processing is
        for research purposes that has appropriate protection for Personal Data,
        or the processing is for establishing legal claims, legal compliance,
        exercise of legal rights or defenses.
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          Contact Details
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        If you wish exercise your right relating to your Personal Data or if you
        have any queries about your Personal Data under this Privacy Policy,
        please contact us or our Data Protection Officer at:
      </p>
      <br />
      <p>
        <font className="font-medium">
          Dusit Thani Properties REIT Company Limited
        </font>
      </p>
      <p>
        319 Chamchuri Square Building 29th Floor, Phayathai Road, Pathumwan,
        Bangkok 10330, Thailand
      </p>
      <p>Tel: 02-200-9999 Ext 3680-3684</p>
      <p>Email: ir@dtcreit.com</p>
    </div>
  </div>
);

export const PrivacyTH = () => (
  <div>
    <h2 className="mb-2 w-fit whitespace-nowrap text-xl font-medium text-gold md:text-2xl">
      นโยบายความเป็นส่วนตัว
    </h2>
    <p>
      บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ รีท จำกัด (“บริษัทฯ” “เรา” หรือ “ของเรา”)
      ในฐานะผู้ควบคุมข้อมูลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
      (“กฎหมายคุ้มครองข้อมูลส่วนบุคคล”)
      มีหน้าที่ต้องปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
      นโยบายความเป็นส่วนตัวฉบับนี้ อธิบายถึงวิธีที่ประมวลผลหรือดำเนินการใด ๆ
      ต่อข้อมูลส่วนบุคคล ไม่ว่าจะเป็นการเก็บ บันทึก จัดระบบ การเก็บรักษา
      การเปลี่ยนแปลง ใช้ เปิดเผย หรือส่งต่อข้อมูล เผยแพร่
      รวมไปถึงการลบและทำลายข้อมูลส่วนบุคคลของท่าน ดังนี้
    </p>
    <br />
    <div className="md:pl-4">
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          การเก็บรวบรวมข้อมูลส่วนบุคคลของเจ้าของข้อมูล
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        เราอาจเก็บรวบรวมข้อมูลส่วนบุคคลของเจ้าของข้อมูล
        ทั้งโดยทางตรงและทางอ้อมจากข้อมูลที่เจ้าของข้อมูล
        หรือตัวแทนของเจ้าของข้อมูล ให้ไว้กับเรา ผ่านทางเว็บไซต์
        พันธมิตรทางธุรกิจ โทรศัพท์ และช่องทางการสื่อสารทางออนไลน์ต่าง ๆ
        ตลอดจนแหล่งที่เชื่อถือได้อื่นๆ เช่น สมาคม หน่วยงานภาครัฐ และภาคเอกชนอื่น
        ๆ เป็นต้น
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          ประเภทของข้อมูลส่วนบุคคลที่เก็บรวบรวม
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        เราอาจมีการเก็บรวบรวมหรือรับข้อมูลประเภทดังต่อไปนี้ซึ่งอาจรวมไปถึงข้อมูลส่วนบุคคลของท่านโดยตรงหรือโดยอ้อมจากท่านหรือแหล่งข้อมูลอื่น
        ๆ
        ซึ่งข้อมูลที่เราเก็บรวบรวมนั้นจะขึ้นอยู่กับบริบทของการติดต่อสื่อสารของท่านกับเรา
      </p>
      <br />
      <ul className="list-inside list-disc">
        <li>
          ข้อมูลส่วนบุคคลในฐานะผู้ถือหน่วยทรัสต์ ประกอบด้วย ชื่อ-นามสกุล สัญชาติ
          อาชีพ ที่อยู่ จำนวนหน่วยทรัสต์ที่ถือ อีเมล หมายเลขโทรศัพท์
          และบัญชีธนาคาร
        </li>
        <li>
          ข้อมูลส่วนบุคคลในฐานะผู้สนใจลงทุนที่ติดต่อมายังบริษัทฯ
          ผ่านช่องทางนักลงทุนสัมพันธ์ เช่น ชื่อ-นามสกุล ตำแหน่ง สถานที่ทำงาน
          ข้อมูลติดต่อต่าง ๆ
        </li>
        <li>
          ข้อมูลที่ท่านสอบถามหรือติดต่อมา หรือ การสมัครอีเมลติดตามข่าวสารของท่าน
        </li>
        <li>ข้อมูลของตัวแทนหรือผู้รับมอบอำนาจของท่าน (ถ้ามี)</li>
        <li>
          ข้อมูลวิดีโอ ภาพและเสียง เมื่อมีการเข้าร่วมประชุมต่างๆ เช่น
          ประชุมสามัญประจำปี (AGM) ประชุมนักวิเคราะห์ (Analyst Meeting)
          และงานพบปะนักลงทุน (Opportunity Day) เป็นต้น
        </li>
        <li>
          ข้อมูลเลขที่อยู่ไอพี ข้อมูลการลงชื่อเข้าใช้
          ประเภทของเว็บเบราว์เซอร์ที่ท่านใช้งาน เขตเวลาและพิกัด
          ข้อมูลเกี่ยวกับอุปกรณ์และซอฟต์แวร์ที่ท่านใช้ติดต่อหรือมีปฏิสัมพันธ์กับเว็บไซต์
        </li>
      </ul>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          ระยะเวลาในการจัดเก็บข้อมูล
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        เราจะเก็บรักษาข้อมูลส่วนบุคคลของเจ้าของข้อมูลไว้เป็นระยะเวลา
        ตราบเท่าที่วัตถุประสงค์ของการนำข้อมูลดังกล่าวไปใช้ยังคงมีอยู่
        หลังจากนั้น เราจะลบและทำลายข้อมูลดังกล่าว
        เว้นแต่กรณีจำเป็นต้องเก็บรักษาข้อมูลต่อไปตามที่กฎหมายที่เกี่ยวข้องกำหนดไว้
        หรือเพื่อเป็นการคุ้มครองสิทธิประโยชน์ของบริษัทฯ โดยปกติในกรณีทั่วไป
        ระยะเวลาการเก็บข้อมูลสูงสุดจะเท่ากับ 10 ปี
        เว้นแต่จะมีกฎหมายกำหนดให้เก็บรักษาข้อมูลไว้เป็นระยะเวลานานกว่าที่กำหนดไว้
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          วัตถุประสงค์ของการใช้ และเปิดเผยข้อมูลส่วนบุคคล
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>บริษัทฯ จะใช้และเปิดเผยข้อมูลส่วนบุคคล ดังนี้</p>
      <br />
      <ul className="list-inside list-disc">
        <li>
          เพื่อจัดส่งหนังสือเชิญประชุมและหนังสือแจ้งใด ๆ ให้แก่ผู้ถือหน่วยทรัสต์
          ผู้ถือหุ้นกู้ ผู้ลงทุน หรือนักวิเคราะห์ ตามที่กฎหมายกำหนด เช่น
          หนังสือเชิญประชุมสามัญประจำปี
          ข้อมูลที่จัดส่งให้นักลงทุนหรือนักวิเคราะห์
          แบบแสดงรายการข้อมูลประจำปี/รายงานประจำปี
          สื่อเผยแพร่หรือจดหมายของบริษัทฯ ใด ๆ ให้แก่ท่าน
        </li>
        <li>
          เพื่อยืนยันตัวตนหรือสิทธิ์ของผู้รับมอบฉันทะในการเข้าร่วมประชุมผู้ถือหน่วยทรัสต์
        </li>
        <li>เพื่อตรวจสอบสิทธิ์ในการโอนหุ้นของท่าน</li>
        <li>
          เพื่อจัดสรรและจ่ายประโยชน์ตอบแทน (ปันผล และลดทุน)
          ให้แก่ผู้ถือหน่วยทรัสต์
        </li>
        <li>เพื่อดำเนินการจัดทำและบันทึกบัญชีตามที่กฎหมายกำหนด</li>
        <li>เพื่อจัดการและตอบสนองต่อคำถาม คำติชม หรือข้อร้องเรียนของท่าน</li>
        <li>
          เพื่อจัดทำแบบแสดงรายการข้อมูลประจำปี/รายงานประจำปีที่บริษัทฯ
          มีหน้าที่ปฏิบัติตามกฎหมาย
          ซึ่งอาจมีการเปิดเผยข้อมูลส่วนบุคคลของท่านในฐานะผู้ถือหน่วยรายใหญ่
          รวมถึงเพื่อจัดส่งรายงานเหล่านั้นไปยังหน่วยงานของรัฐตามข้อกำหนดและกฎหมายที่เกี่ยวข้อง
        </li>
        <li>เพื่อการรักษาความปลอดภัยของข้อมูลและเครือข่าย</li>
        <li>
          เพื่อปฏิบัติตามกฎหมายต่าง ๆ ที่กำหนดให้บริษัทฯ
          จำเป็นต้องประมวลผลข้อมูลส่วนบุคคลของท่าน
        </li>
        <li>
          เพื่อการตรวจสอบ วิเคราะห์
          และจัดเตรียมเอกสารตามที่หน่วยงานราชการที่เกี่ยวข้องร้องขอ
        </li>
      </ul>
      <br />
      <p>
        ในกรณีที่ บริษัทฯ ใช้บริการจากผู้ประมวลผลข้อมูลส่วนบุคคลภายนอก
        เราจะประมวลผลข้อมูลส่วนบุคคลของเจ้าของข้อมูลเพื่อการปฏิบัติหน้าที่ตามสัญญา
        การปฏิบัติหน้าที่ตามกฎหมาย ประโยชน์โดยชอบด้วยกฎหมาย
        หรือตามที่เจ้าของข้อมูลให้ความยินยอมไว้ (ในกรณีที่จำเป็น)
        โดยจะไม่เปิดเผย หรือทำให้ข้อมูลของเจ้าของข้อมูลไปเปิดเผย แสดง
        หรือทำให้ปรากฏในลักษณะอื่นใดที่ไม่สอดคล้องกับจุดประสงค์เพื่อการปฏิบัติหน้าที่ตามสัญญา
        การปฏิบัติหน้าที่ตามกฎหมาย ประโยชน์โดยชอบด้วยกฎหมาย
        หรือตามที่เจ้าของข้อมูลได้ให้ความยินยอมไว้ เว้นแต่
        จะได้รับความยินยอมจากเจ้าของข้อมูลก่อน
        หรือเป็นกรณีที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนดให้สามารถดำเนินการได้โดยไม่ต้องได้รับความยินยอมจากเจ้าของข้อมูล
      </p>
      <br />
      <p>
        หากเจ้าของข้อมูลไม่สามารถให้ข้อมูลส่วนบุคคลที่จำเป็นต่อการประมวลผลข้อมูลส่วนบุคคลเพื่อการปฏิบัติหน้าที่ตามสัญญา
        หรือเพื่อการปฏิบัติตามกฎหมาย หรือเพื่อประโยชน์โดยชอบด้วยกฎหมาย บริษัทฯ
        จะไม่สามารถปฏิบัติตามสัญญาที่มีต่อเจ้าของข้อมูล
        หรือไม่สามารถปฏิบัติตามกฎหมายได้
      </p>
      <br />
      <p>
        ทั้งนี้ บริษัทฯ จะประมวลผลข้อมูลส่วนบุคคล
        โดยปฏิบัติตามกฎหมายที่เกี่ยวข้องอย่างเคร่งครัด และในกรณีที่ บริษัทฯ
        ได้รับความยินยอมจากเจ้าของข้อมูล
        เจ้าของข้อมูลสามารถเพิกถอนความยินยอมได้ตลอดเวลา
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          ผู้ที่ได้รับการเปิดเผยข้อมูลส่วนบุคคลจากบริษัทฯ
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        เราจะไม่เปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูล
        โดยไม่มีฐานการประมวลผลข้อมูลโดยชอบด้วยกฎหมาย โดยข้อมูลของเจ้าของข้อมูล
        อาจเปิดเผย หรือโอนไปยังองค์กร หน่วยงานของรัฐ หรือบุคคลภายนอก รวมถึง
      </p>
      <br />
      <p>
        <font className="font-medium">ผู้ให้บริการภายนอก</font> :
        เราอาจทำการเปิดเผยข้อมูลส่วนบุคคลของท่านไปให้กับผู้ให้บริการภายนอก เช่น
        บริษัท ศูนย์รับฝากหลักทรัพย์ (ประเทศไทย) จำกัด ในฐานะนายทะเบียน
        เพื่อปฏิบัติหน้าที่ตามสัญญาที่บริษัทฯ มีต่อท่าน ในการนี้
        เราจะทำการเปิดเผยข้อมูลเฉพาะเท่าที่จำเป็นสำหรับดำเนินการตามหน้าที่ดังกล่าวเท่านั้น
        นอกจากนี้เราจะเข้าทำข้อตกลงกับผู้ให้บริการภายนอกเพื่อให้มั่นใจในความปลอดภัยของข้อมูลส่วนบุคคลของท่านที่ได้เปิดเผยไปให้แก่ผู้ให้บริการภายนอก
        ในบางกรณีผู้ให้บริการภายนอกอาจมีสถานะเป็นผู้ควบคุมข้อมูลส่วนบุคคล
        เพราะฉะนั้น
        เราจึงขอแนะนำให้ท่านศึกษาเพิ่มเติมจากนโยบายคุ้มครองข้อมูลส่วนบุคคลของผู้ให้บริการภายนอกรายนั้นโดยตรง
        ซึ่งผู้ให้บริการภายนอกดังกล่าวอาจจัดอยู่ในหมวดหมู่ต่อไปนี้ เช่น
        ที่ปรึกษาทางภาษี และที่ปรึกษาการเงิน เป็นต้น
      </p>
      <p>
        <font className="font-medium">หน่วยงานราชการ</font> :
        เราอาจทำการเปิดเผยข้อมูลส่วนบุคคลของท่านไปยังหน่วยงานราชการที่เกี่ยวข้องเพื่อการปฏิบัติตามกฎหมาย
        หรือข้อกำหนดทางกฎหมายอื่นใด
      </p>
      <br />
      <p>
        นอกจากนี้ เราจะทำการเปิดเผยข้อมูลส่วนบุคคลต่อผู้รับข้อมูลในต่างประเทศ
        เฉพาะกรณีที่มีความจำเป็นต้องปฏิบัติตามภาระผูกพันที่บริษัทฯ มีต่อท่าน
        และได้รับอนุญาตตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
        และกฎหมายอื่นที่เกี่ยวข้องกำหนดให้ทำได้เท่านั้น
      </p>
      <br />
      <p>
        ในกรณีที่
        เราจำเป็นต้องส่งข้อมูลส่วนบุคคลของเจ้าของข้อมูลให้แก่บุคคลภายนอก
        เราจะดำเนินการตามขั้นตอนที่เหมาะสม เพื่อให้มั่นใจว่า
        บุคคลภายนอกจะดูแลข้อมูลส่วนบุคคลของเจ้าของข้อมูล ไม่ให้เกิดการสูญหาย
        การเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต การใช้ การดัดแปลง
        หรือการเปิดเผยและการใช้งานที่ไม่ถูกต้อง
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          มาตรการรักษาความปลอดภัยของข้อมูล
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        เราใช้ระบบรักษาความปลอดภัยที่มีมาตรฐานสูงทั้งในด้านเทคโนโลยี
        และกระบวนการ เพื่อป้องกันการโจรกรรมข้อมูล บริษัทฯ ได้ลงทุน ทุ่มเทเวลา
        และบุคลากรเป็นจำนวนมาก เพื่อให้มั่นใจว่า
        มีการรักษาความปลอดภัยที่ได้มาตรฐานสูง ข้อมูลของเจ้าของข้อมูลจะปลอดภัย
        ด้วยระบบคอมพิวเตอร์ของดุสิต ได้รับการปกป้องด้วยมาตรการต่าง ๆ เช่น
        Network Firewall, Intrusion Detection, Threat Prevention, URL Filtering,
        Application Control, Email Security, Server and Endpoint Protection,
        Secure Socket Layer, Cookies เป็นต้น
      </p>
      <br />
      <p>
        ถึงแม้ เราจะทุ่มเท และใช้ความพยายามในการดูแลข้อมูลให้มีความปลอดภัย
        ด้วยการใช้เครื่องมือทางเทคนิคร่วมกับการบริหารจัดการโดยบุคคล
        เพื่อควบคุมและรักษาความปลอดภัยข้อมูล
        มิให้มีการเข้าถึงข้อมูลส่วนตัวหรือข้อมูลที่เป็นความลับของเจ้าของข้อมูลโดยไม่ได้รับอนุญาต
        แต่อาจไม่สามารถป้องกันความผิดพลาดได้ทุกประการ เช่น
        การปกป้องข้อมูลของเจ้าของข้อมูลจากการถูกจู่โจมโดยไวรัสคอมพิวเตอร์
        หรือถูกเข้าถึงโดยบุคคลที่ไม่มีอำนาจได้
        เจ้าของข้อมูลจึงควรติดตามข่าวสารเกี่ยวกับคอมพิวเตอร์ให้ทันสมัยอยู่เสมอ
        ติดตั้งซอฟแวร์ประเภท personal firewall
        เพื่อป้องกันคอมพิวเตอร์จากการจู่โจม หรือโจรกรรมข้อมูล
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          สิทธิของเจ้าของข้อมูล
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        การใช้สิทธิในเรื่องใด ๆ ตามที่ระบุไว้ในข้อนี้
        ให้เป็นไปตามหลักเกณฑ์และวิธีการใช้สิทธิของเจ้าของข้อมูล
        ตามที่กำหนดไว้ในข้อนี้ อนึ่ง สิทธิตามที่กำหนดไว้ในข้อนี้
        อาจมีการปรับปรุงแก้ไขให้เป็นไปตามหลักเกณฑ์ที่กฎหมายกำหนด
        ซึ่งอาจมีการออกหลักเกณฑ์โดยรัฐเป็นคราว ๆ โดย บริษัทฯ
        จะแจ้งให้เจ้าของข้อมูลทราบต่อไป
      </p>
      <br />
      <ul className="list-inside list-disc">
        <li>
          <font className="font-medium">สิทธิในการได้รับแจ้งข้อมูล</font> :
          ในกรณีที่ เรามีการเก็บ รวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
          ซึ่งไม่เป็นไปตามวัตถุประสงค์ หรือที่นอกเหนือจากความยินยอมใด ๆ
          ที่ได้ให้ไว้ เราจะแจ้ง
          และ/หรือขอความยินยอมจากเจ้าของข้อมูลก่อนการประมวลผลข้อมูลส่วนบุคคลที่อยู่นอกวัตถุประสงค์ดังกล่าว
        </li>
        <li>
          <font className="font-medium">สิทธิในการเข้าถึงข้อมูลส่วนบุคคล</font>:
          เจ้าของข้อมูลมีสิทธิที่จะขอรับสำเนาข้อมูลส่วนบุคคลของตน
          และมีสิทธิที่จะร้องขอให้ เปิดเผยถึงการได้มาซึ่งข้อมูลของเจ้าของข้อมูล
        </li>
        <li>
          <font className="font-medium">
            สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง
          </font>
          : ในกรณีที่ข้อมูลส่วนบุคคลของเจ้าของข้อมูลมีการเปลี่ยนแปลง
          เจ้าของข้อมูลจะดำเนินการยื่นคำขอแก้ไขข้อมูลดังกล่าว
          เพื่อทำให้ข้อมูลส่วนบุคคลของเจ้าของข้อมูลนั้นถูกต้อง เป็นปัจจุบัน
          สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
        </li>
        <li>
          <font className="font-medium">สิทธิในการโอนถ่ายข้อมูลส่วนบุคคล</font>:
          ในกรณีที่ระบบของบริษัทฯ รองรับ
          เจ้าของข้อมูลมีสิทธิที่จะรับข้อมูลส่วนบุคคลของตน
          ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้
          โดยเครื่องมืออุปกรณ์อัตโนมัติ หรือขอให้โอนโดยอัตโนมัติได้
        </li>
        <li>
          <font className="font-medium">สิทธิในการขอให้ลบข้อมูลส่วนบุคคล</font>:
          จ้าของข้อมูลมีสิทธิขอให้ลบหรือทำลาย
          หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลได้ในกรณีหนึ่งกรณีใดดังต่อไปนี้
          (ก) หมดความจำเป็นอีกต่อไปในการเก็บ รวบรวม
          ใช้หรือเปิดเผยข้อมูลส่วนบุคคลตามวัตถุประสงค์ (ข)
          เมื่อเจ้าของข้อมูลถอนความยินยอมในการเก็บ รวบรวม
          ใช้หรือเปิดเผยข้อมูลส่วนบุคคล โดยบริษัทฯ
          ไม่มีอำนาจตามกฎหมายที่จะประมวลผลข้อมูลนั้นอีกต่อไป (ค)
          เมื่อเจ้าของข้อมูลคัดค้านการประมวลผลข้อมูลส่วนบุคคล หรือ (ง)
          เมื่อข้อมูลส่วนบุคคลได้ถูกประมวลผลโดยไม่ชอบด้วยกฎหมายคุ้มครองข้อมูลส่วนบุคคล
        </li>
        <li>
          <font className="font-medium">
            สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล
          </font>
          : เจ้าของข้อมูลมีสิทธิขอให้บริษัทฯ ระงับการใช้ข้อมูลส่วนบุคคลได้ดังนี้
          <ol className="list-inside list-decimal pl-8">
            <li>
              เมื่อบริษัทฯ
              อยู่ระหว่างการตรวจสอบข้อมูลตามที่เจ้าของข้อมูลร้องขอให้ดำเนินการปรับปรุงเพื่อทำให้ข้อมูลส่วนบุคคลนั้นถูกต้อง
              เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
            </li>
            <li>
              เมื่อเป็นข้อมูลที่ต้องลบหรือทำลาย
              แต่เจ้าของข้อมูลขอให้ระงับการใช้ข้อมูลแทน
            </li>
            <li>
              เมื่อข้อมูลส่วนบุคคลหมดความจำเป็นในการเก็บรักษาไว้
              แต่เจ้าของข้อมูลขอให้บริษัทฯ
              เก็บรักษาข้อมูลไว้ก่อนเพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย
              การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย
              หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
            </li>
            <li>
              เมื่อบริษัทฯ อยู่ในระหว่างการพิสูจน์การเก็บรวมรวมข้อมูลส่วนบุคคล
              หรืออยู่ระหว่างตรวจสอบการประมวลผลข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ตามที่กฎหมายกำหนด
            </li>
          </ol>
        </li>
        <li>
          <font className="font-medium">
            สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล
          </font>
          : เจ้าของข้อมูลมีสิทธิที่จะคัดค้านการเก็บ รวบรวม
          ใช้หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวข้องกับตนได้ ในกรณีดังต่อไปนี้
          <ol className="list-inside list-decimal pl-8">
            <li>
              เป็นข้อมูลที่บริษัทฯ ได้มีการเก็บรวมรวมข้อมูลดังกล่าว (ก)
              จากการปฏิบัติหน้าที่ของบริษัทฯ จากคำสั่งของรัฐ หรือ (ข)
              จากความจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ
              หรือของนิติบุคคลอื่น
            </li>
            <li>
              เป็นกรณีที่บริษัทฯ ประมวลผลข้อมูลส่วนบุคคล
              เพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรง
            </li>
            <li>
              เป็นกรณีที่บริษัทฯ ประมวลผลข้อมูลส่วนบุคคล
              เพื่อวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยในด้านต่าง ๆ ที่กฎหมายกำหนด
              ซึ่งรวมถึงทางสถิติ
            </li>
          </ol>
        </li>
        <li>
          <font className="font-medium">สิทธิในการเพิกถอนความยินยอม</font>:
          เจ้าของข้อมูลมีสิทธิที่จะเพิกถอนความยินยอมเมื่อใดก็ได้
          โดยการถอนความยินยอมดังกล่าวจะไม่กระทบต่อการประมวลผลข้อมูลส่วนบุคคลใด ๆ
          ที่เจ้าของข้อมูลได้ให้ความยินยอมไปแล้วก่อนหน้านี้ ทั้งนี้
          หากการถอนความยินยอมจะส่งผลกระทบต่อข้อมูลส่วนบุคคลในเรื่องใด
          เราจะแจ้งให้เจ้าของข้อมูลทราบถึงผลกระทบจากการถอนความยินยอม
        </li>
      </ul>
      <br />
      <p>
        อนึ่ง บริษัทฯ อาจปฏิเสธคำขอใช้สิทธิข้างต้น หากการดำเนินการใด ๆ
        เป็นไปเพื่อวัตถุประสงค์หรือเป็นกรณีที่ต้องปฏิบัติตามกฎหมายหรือคำสั่งศาล
        หรือเป็นกรณีที่อาจส่งผลกระทบและก่อให้เกิดความเสียหายต่อสิทธิหรือเสรีภาพของเจ้าของข้อมูลหรือบุคคลอื่น
        หรือเป็นการดำเนินการเพื่อการศึกษาวิจัยทางสถิติที่มีมาตรการปกป้องข้อมูลที่เหมาะสม
        หรือเพื่อการก่อตั้งสิทธิเรียกร้อง
        การปฏิบัติตามหรือการใช้สิทธิเรียกร้องหรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
      </p>
      <br />
      <div className="flex w-full gap-2">
        <h2 className="mb-2 w-fit whitespace-nowrap text-lg font-medium md:text-xl">
          รายละเอียดการติดต่อเรา
        </h2>
        <div className="mb-2 w-full border-b border-gold"></div>
      </div>
      <p>
        หากท่านมีความประสงค์ที่จะใช้สิทธิของท่านที่เกี่ยวข้องกับข้อมูลส่วนบุคคลหรือหากท่านมีข้อสงสัยเกี่ยวกับข้อมูลส่วนบุคคลภายใต้นโยบายความเป็นส่วนตัวฉบับนี้
        โปรดติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราที่
      </p>
      <br />
      <p>
        <font className="font-medium">
          บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ รีท จำกัด
        </font>
      </p>
      <p>
        319 อาคารจามจุรีสแควร์ ชั้น 29 ถนน พญาไท ปทุมวัน กรุงเทพมหานคร 10330
      </p>
      <p>โทรศัพท์ 02-200-9999 ต่อ 3680-3684</p>
      <p>อีเมล์: ir@dtcreit.com</p>
    </div>
  </div>
);
