const BoardData = [
  {
    lng: "en",
    data: [
      {
        name: "chanin",
        edu: [
          "Master of Business Administration (MBA), Boston University, USA",
          "Director Certification Program (72/2006), Thai Institute of Directors Association",
          "Director Accreditation Program (10/2004), Thai Institute of Directors Association",
          "The Board's Role in Merger and Acquisition (1/2022), Thai Institute of Directors Association",
          "Role of the Chairman Program (51/2022), Thai Institute of Directors Association",
          "Cyber Resilience Leadership: SMART Goal at Bank of Thailand ",
        ],
        current: [
          {
            year: "2022 - Present",
            position: [
              "Director, Le Cordon Bleu Dusit Company Limited",
              "Credit and Investment Screening Committee / Audit Committee, Kasikorn Bank Public Company Limited",
            ],
          },
          {
            year: "2020 - Present",
            position: [
              "Chairman of Board of Tourism Business Strategy, Thai Chamber of Commerce",
              "Chairman, Thai Digital Platform Social Enterprise Company Limited",
              "Head of Private Sector, Public-Private Steering Committee, D6 MICE and tourism promotion",
            ],
          },
          {
            year: "2018 - Present",
            position: ["Director, Dusit Foods Company Limited"],
          },
          {
            year: "2017 - Present",
            position: [
              "Director / Corporate Governance Committee, Kasikorn Bank Public Company Limited",
              "Chairman, Dusit Thani Properties REIT Company Limited",
              "Director, D&J Company Limited",
            ],
          },
          {
            year: "2016 - Present",
            position: [
              "Vice Chairman / Chairman of the Executive Board / Investment Committee, Dusit Thani Public Company Limited",
              "Director, Viman Suriya Company Limited",
            ],
          },
          {
            year: "2015 - Present",
            position: ["Director, Piyasiri Company Limited"],
          },
          {
            year: "2014 - Present",
            position: [
              "Director, Dusit Fudu Hotel Management (Shanghai) Company Limited",
            ],
          },
          {
            year: "2013 - Present",
            position: [
              "Director, JTB (Thailand) Company Limited",
              "Director, Dusit China Capital Company Limited",
            ],
          },
          {
            year: "2012 - Present",
            position: ["Director, Dusit USA Management Inc."],
          },
          {
            year: "2011 - Present",
            position: ["Director, DMS Property Investment Private Limited"],
          },
          {
            year: "2010 - Present",
            position: ["Director, Chanut & Children Company Limited"],
          },
          {
            year: "2009 - Present",
            position: [
              "Director, Dusit Thai Properties Public Company Limited",
            ],
          },
          {
            year: "2008 - Present",
            position: ["Director, Dusit Overseas Company Limited"],
          },
          {
            year: "1995 - Present",
            position: ["Chairman, Dusit Thani Philippines, Inc."],
          },
          {
            year: "1990 - Present",
            position: ["Director, Dusit Thani Properties Company Limited"],
          },
          {
            year: "1989 - Present",
            position: ["Director, Thai - Japan Gas Company Limited"],
          },
          {
            year: "1988 - Present",
            position: ["Director, Thana Jirang Company Limited "],
          },
        ],
      },
      {
        name: "suphajee",
        edu: [
          "Master of Business Administration (MBA), International Finance and International Accounting, Northrop University, California, USA",
          "Bachelor of Arts in Sociology and Anthropology, Thammasat University",
          "Advanced Audit Committee Program (23/2016), Thai Institute of Directors Association",
          "Director Certification Program (89/2007), Thai Institute of Directors Association",
        ],
        current: [
          {
            year: "2022 - Present",
            position: [
              "Director, Bonjour Company Limited / Port Royal Company Limited / BAKEIP Limited / Baujour International Co Limited",
              "Director, Bonjour Bakery Asia Company Limited",
              "Director, Suanlum Property Company Limited",
              "Director, The Cooking Capital Bangkok Company Limited / The Food Education Bangkok Company Limited",
            ],
          },
          {
            year: "2021 - Present",
            position: [
              "Director / Member of Governance and Nomination Committee, SCG Packaging Public Company Limited",
            ],
          },
          {
            year: "2020 - Present",
            position: [
              "Chairman – Board of Human Resource and Renumeration, Kasikorn Bank Public Company Limtied",
            ],
          },
          {
            year: "2019 - Present",
            position: [
              "Director, Dusit Real Foods Company Limited",
              "Director, Dusit Thani Properties REIT Company Limited",
              "Director, Dusit Fudu Hotel Management (Shanghai) Company Limited",
              "Director, Epicure Catering Company Limited",
            ],
          },
          {
            year: "2018 - Present",
            position: [
              "Director, LVM Holding Pte. Ltd.",
              "Director, Dusit Gourmet Company Limited",
              "Director, Dusit Foods Company Limited",
            ],
          },
          {
            year: "2017 - Present",
            position: [
              "Director, ASAI Holdings Company Limited",
              "Director, D&J Company Limited",
            ],
          },
          {
            year: "2016 - Present",
            position: [
              "Director, Vimarn Suriya Company Limited",
              "Director, Dusit Thani Properties Company Limited / Le Cordon Bleu Dusit Company Limited / Dusit Management Company Limited / Dusit China Capital Company Limited",
              "Director, Dusit Overseas Company Limited / Dusit Thani Philippines, Inc. / Dusit Hospitality Education Philippines, Inc. / DMS Property Investment Private Limited",
            ],
          },
          {
            year: "2015 - Present",
            position: [
              "Director / Audit Committee / Member of Board of Risk Management. Kasikorn Bank Public Company Limited",
              "Director / Executive Director / Chief Executive Director / Investment Committee, Dusit Thani Public Company Limited",
              "Council Committee, Dusit Thani College ",
            ],
          },
        ],
      },
      {
        name: "pattaneeporn",
        edu: [
          "Master of Business Administration (MBA)",
          "Master of Science in Information Systems, Boston University, USA",
          "Master of Arts in Economics, Boston University, USA",
          "Director Certification Program (297/2020), Thai Institute of Directors Association",
        ],
        current: [
          {
            year: "2019 - Present",
            position: [
              "Director, Dusit Thani Properties REIT Company Limited",
              "Director, Dusit Thani REIT Company Limited",
              "Director, Dusit Maldives Investment Private Limited",
              "Director, Dusit Maldives Management Private Limited",
              "Director, Dusit Worldwide Company Limited",
            ],
          },
          {
            year: "2017 - Present",
            position: ["Director, ASAI Holdings Company Limited"],
          },
          {
            year: "2016 - Present",
            position: [
              "Director, Suanlum Property Company Limited",
              "Director, Pharam 4 Development Company Limited",
            ],
          },
          {
            year: "2014 - Present",
            position: ["Director, Dusit Excellence Company Limited"],
          },
          {
            year: "2013 - 2021",
            position: ["Director, Dusit China Capital Company Limited"],
          },
          {
            year: "2011 - Present",
            position: ["Director, DMS Property Investment Private Limited"],
          },
          {
            year: "2010 - Present",
            position: ["Director, Dusit Management Company Limited"],
          },
          {
            year: "2009 - Present",
            position: [
              "Director, Dusit Thani Properties Company Limited",
              "Director, Dusit Thai Properties Public Company Limited",
            ],
          },
          {
            year: "2008 - Present",
            position: [
              "Director, Dusit Worldwide Company Limited",
              "Director, Dusit Overseas Company Limited",
            ],
          },
          {
            year: "2005 - Present",
            position: [
              "Vice President - Finance, Dusit Thani Public Company Limited",
            ],
          },
          {
            year: "2002 - Present",
            position: ["Director, Acme Printing Company Limited"],
          },
        ],
      },
      {
        name: "natharin",
        edu: [
          "Master of Science in Finance, University of Colorado, USA",
          "Bachelor of Science in Economics, Kasetsart University",
        ],
        current: [
          {
            year: "2017 - Present",
            position: [
              "Director / Independent Director, Dusit Thani Properties REIT Company Limited",
            ],
          },
          {
            year: "2013 - Present",
            position: [
              "Independent Director / Chairman of Audit Committee / Risk Management Committee / Nomination and Remuneration Committee, Malee Group Public Company Limited",
            ],
          },
        ],
      },
      {
        name: "virapan",
        edu: [
          "Master of Science in Electrical Engineering at University of Colorado with Scholarship from IBM",
          "Bachelor of Science in Electrical Engineering and Computer Science at University of Colorado with 1st degree honor",
          "Director Certification Program, Thai Institute of Directors Association",
          "Audit Committee Program, Thai Institute of Directors Association",
          "Role of Chairman Program, Thai Institute of Directors Association",
        ],
        current: [
          {
            year: "2022 - Present",
            position: [
              "Independent Director / Chairman of the Board, Nutrition Profess Company Limited",
              "Director, Asia Industrial Estate Company Limited",
            ],
          },
          {
            year: "2021 - Present",
            position: [
              "Advisor to Chairman, Asia Industrial Estate Company Limited",
            ],
          },
          {
            year: "2018 - Present",
            position: [
              "Chairman / Independent Director / Chairman of the Nomination and Remuneration Committee, Neo Corporate Company Limited",
              "Independent Director, Dusit Thani Properties REIT Company Limited",
            ],
          },
        ],
      },
      {
        name: "voranon",
        edu: [
          "Master of Science in Real Estate Business, Thammasat University",
        ],
        current: [
          {
            year: "2022 - Present",
            position: [
              "Director / Managing Director, Dusit Thani Properties REIT Company Limited",
              "Director, Dusit Thani REIT Company Limited",
              "Director, Dusit Maldives Investment Private Limited",
            ],
          },
        ],
      },
    ],
  },
  {
    lng: "th",
    data: [
      {
        name: "chanin",
        edu: [
          "ปริญญาโท สาขาบริหารธุรกิจ Boston University ประเทศสหรัฐอเมริกา",
          "Director Certification Program รุ่น 72/2549 สมาคมส่งเสริมสถาบันกรรมการบริษัทไทย",
          "Director Accreditation Program รุ่น 10/2547 สมาคมส่งเสริมสถาบันกรรมการบริษัทไทย",
          "The Board's Role in Merger and Acquisition รุ่น 1/2565 สมาคมส่งเสริมสถาบันกรรมการบริษัทไทย",
          "Role of the Chairman Program รุ่น 51/2565 สมาคมส่งเสริมสถาบันกรรมการบริษัทไทย",
          "สัมมนาเชิงปฏิบัติการด้านความมั่นคงปลอดภัยไซเบอร์จากธนาคารแห่งประเทศไทย",
        ],
        current: [
          {
            year: "2565 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท เลอ กอร์ดอง เบลอ ดุสิต จำกัด",
              "กรรมการกลั่นกรองสินเชื่อและการลงทุน / กรรมการตรวจสอบ ธนาคารกสิกรไทย จำกัด (มหาชน)",
            ],
          },
          {
            year: "2563 - ปัจจุบัน",
            position: [
              "ประธานกรรมการธุรกิจการท่องเที่ยวและบริการ หอการค้าไทย",
              "ประธานกรรมการ บริษัท ไทย ดิจิทัล แพลตฟอร์ม วิสาหกิจเพื่อสังคม จำกัด",
              "หัวหน้าทีมภาคเอกชน คณะทำงานร่วมรัฐ-เอกชน เพื่อขับเคลื่อนเศรษฐกิจของประเทศ D6 การส่งเสริมการท่องเที่ยว และ MICE",
            ],
          },
          {
            year: "2561 - ปัจจุบัน",
            position: ["กรรมการ บริษัท ดุสิต ฟู้ดส์ จำกัด"],
          },
          {
            year: "2560 - ปัจจุบัน",
            position: [
              "กรรมการ / กรรมการกำกับดูแลกิจการ  ธนาคารกสิกรไทย จำกัด (มหาชน) ",
              "ประธานกรรมการ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ รีท จำกัด",
              "กรรมการ D&J Company Limited",
            ],
          },
          {
            year: "2559 - ปัจจุบัน",
            position: [
              "รองประธานกรรมการ / ประธานกรรมการบริหาร / กรรมการการลงทุน บริษัท ดุสิตธานี จำกัด (มหาชน)",
              "กรรมการ บริษัท วิมานสุริยา จำกัด",
            ],
          },
          {
            year: "2558 - ปัจจุบัน",
            position: ["บริษัท ปิยะศิริ จำกัด"],
          },
          {
            year: "2557 - ปัจจุบัน",
            position: [
              "กรรมการ Dusit Fudu Hotel Management (Shanghai) Company Limited",
            ],
          },
          {
            year: "2556 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท เจทีบี (ประเทศไทย) จำกัด",
              "กรรมการ บริษัท ดุสิต ไชน่า แคปปิตอล จำกัด",
            ],
          },
          {
            year: "2555 - ปัจจุบัน",
            position: ["กรรมการ Dusit USA Management Inc."],
          },
          {
            year: "2554 - ปัจจุบัน",
            position: ["กรรมการ DMS Property Investment Private Limited"],
          },
          {
            year: "2553 - ปัจจุบัน",
            position: ["กรรมการ บริษัท ชนัตถ์และลูก จำกัด"],
          },
          {
            year: "2552 - ปัจจุบัน",
            position: ["กรรมการ บริษัท ดุสิต ไทยพ็อพเพอร์ตี้ส์ จำกัด (มหาชน)"],
          },
          {
            year: "2551 - ปัจจุบัน",
            position: ["กรรมการ Dusit Overseas Company Limited"],
          },
          {
            year: "2538 - ปัจจุบัน",
            position: ["ประธานกรรมการ Dusit Thani Philippines, Inc."],
          },
          {
            year: "2533 - ปัจจุบัน",
            position: ["กรรมการ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ จำกัด"],
          },
          {
            year: "2532 - ปัจจุบัน",
            position: ["กรรมการ บริษัท ไทย-เจแปน แก๊ส จำกัด"],
          },
          {
            year: "2531 - ปัจจุบัน",
            position: ["กรรมการ บริษัท ธนจิรัง จำกัด"],
          },
        ],
      },
      {
        name: "suphajee",
        edu: [
          "ปริญญาโท สาขาบริหารธุรกิจการเงินและการบัญชีต่างประเทศ Northrop University ประเทศสหรัฐอเมริกา",
          "ปริญญาตรี สาขาสังคมวิทยาและมานุษยวิทยา มหาวิทยาลัยธรรมศาสตร์",
          "Advanced Audit Committee Program รุ่น 23/2559 สมาคมส่งเสริมสถาบันกรรมการบริษัทไทย",
          "Director Certification Program รุ่น 89/2550 สมาคมส่งเสริมสถาบันกรรมการบริษัทไทย",
        ],
        current: [
          {
            year: "2565 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท บองชู จำกัด / บริษัท พอร์ต รอยัล จำกัด / BAKEIP Limited / Baujour International Co Limited",
              "กรรมการ บริษัท บองชูร์ เบเกอรี่ เอเชีย จำกัด",
              "กรรมการ บริษัท สวนลุม พร็อพเพอร์ตี้ จำกัด",
              "กรรมการ บริษัท เดอะ คุ๊กกิ้ง แคปิตอล แบงคอก จำกัด / บริษัท เดอะ ฟู้ดเอ็ดดูเคชั่น แบงคอก จำกัด",
            ],
          },
          {
            year: "2564 - ปัจจุบัน",
            position: [
              "กรรมการ / กรรมการบรรษัทภิบาลและสรรหา บริษัท เอสซีจี แพคเกจจิ้ง จำกัด (มหาชน)",
            ],
          },
          {
            year: "2563 - ปัจจุบัน",
            position: [
              "ประธานกรรมการทรัพยากรบุคคลและกำหนดอัตราค่าตอบแทน ธนาคารกสิกรไทย จำกัด (มหาชน)",
            ],
          },
          {
            year: "2562 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท ดุสิต เรียล ฟู้ดส์ จำกัด",
              "กรรมการ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ รีท จำกัด",
              "กรรมการ Dusit Fudu Hotel Management (Shanghai) Company Limited",
              "กรรมการ บริษัท เอ็บพอคิวร์ เคเทอริ่ง จำกัด",
            ],
          },
          {
            year: "2561 - ปัจจุบัน",
            position: [
              "กรรมการ LVM Holding Pte. Ltd.",
              "กรรมการ บริษัท ดุสิต กูร์เมต์ จำกัด",
              "กรรมการ บริษัท ดุสิต ฟู้ดส์ จำกัด",
            ],
          },
          {
            year: "2560 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท อาศัย โฮลดิ้งส์ จำกัด",
              "กรรมการ D&J Company Limited",
            ],
          },
          {
            year: "2559 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท วิมานสุริยา จำกัด",
              "กรรมการ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ จำกัด / บริษัท เลอ กอร์ดอง เบลอ ดุสิต จำกัด / บริษัท ดุสิต แมนเนจเม้นท์ จำกัด / บริษัท ดุสิต ไชน่า แคปปิตอล จำกัด",
              "กรรมการ Dusit Overseas Company Limited / Dusit Thani Philippines, Inc. / Dusit Hospitality Education Philippines, Inc. / DMS Property Investment Private Limited",
            ],
          },
          {
            year: "2558 - ปัจจุบัน",
            position: [
              "กรรมการ / กรรมการตรวจสอบ / สมาชิกคณะกรรมการกำกับความเสี่ยง ธนาคารกสิกรไทย จำกัด (มหาชน)",
              "กรรมการ / กรรมการบริหาร / กรรมการลงทุน / ประธานเจ้าหน้าที่บริหารกลุ่ม บริษัท ดุสิตธานี จำกัด (มหาชน)",
              "กรรมการสภาผู้ทรงคุณวุฒิ วิทยาลัยดุสิตธานี",
            ],
          },
        ],
      },
      {
        name: "pattaneeporn",
        edu: [
          "ปริญญาโท สาขาบริหารธุรกิจ Boston University ประเทศสหรัฐอเมริกา",
          "ปริญญาโท สาขา Information Systems Boston University ประเทศสหรัฐอเมริกา",
          "ปริญญาโท สาขาเศรษฐศาสตร์ Boston University ประเทศสหรัฐอเมริกา",
          "Director Certification Program รุ่น 297/2563สมาคมส่งเสริมสถาบันกรรมการบริษัทไทย",
        ],
        current: [
          {
            year: "2562 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ รีท จำกัด",
              "กรรมการ บริษัท ดุสิตธานี รีท จำกัด",
              "กรรมการ บริษัท ดุสิต มัลดีฟส์ อินเวสเม้นท์ จำกัด",
              "กรรมการ บริษัท ดุสิต มัลดีฟส์ แมนเนจเม้นท์ จำกัด",
              "กรรมการ บริษัท เวิล์ดวายด์ มัลดีฟส์ จำกัด",
            ],
          },
          {
            year: "2560 - ปัจจุบัน",
            position: ["กรรมการ บริษัท อาศัย โฮลดิ้งส์ จำกัด"],
          },
          {
            year: "2559 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท สวนลุม พร็อพเพอร์ตี้ส์ จำกัด",
              "กรรมการ บริษัท พระราม 4 เดเวลอปเม้นท์ จำกัด",
            ],
          },
          {
            year: "2557 - ปัจจุบัน",
            position: ["กรรมการ บริษัท ดุสิต เอ็กเซลเลนซ์ จำกัด"],
          },
          {
            year: "2556 - 2564",
            position: ["กรรมการ บริษัท ดุสิตไชน่า แคปปิตอล จำกัด"],
          },
          {
            year: "2554 - ปัจจุบัน",
            position: ["กรรมการ DMS Property Investment Private Limited"],
          },
          {
            year: "2553 - ปัจจุบัน",
            position: ["กรรมการ บริษัท ดุสิต แมนเนจเม้นท์ จำกัด"],
          },
          {
            year: "2552 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ จำกัด",
              "กรรมการ บริษัท ดุสิต ไทยพร็อพเพอร์ตี้ จำกัด (มหาชน)",
            ],
          },
          {
            year: "2551 - ปัจจุบัน",
            position: [
              "กรรมการ บริษัท ดุสิต เวิล์ดวายด์ จำกัด",
              "กรรมการ Dusit Overseas Company Limited",
            ],
          },
          {
            year: "2548 - ปัจจุบัน",
            position: ["รองประธานฝ่ายการเงิน บริษัท ดุสิตธานี จำกัด (มหาชน)"],
          },
          {
            year: "2545 - ปัจจุบัน",
            position: ["กรรมการ บริษัท แอคมี พรินติ้ง จำกัด"],
          },
        ],
      },
      {
        name: "natharin",
        edu: [
          "ปริญญาโท สาขาการเงิน University of Colorado ประเทศสหรัฐอเมริกา",
          "ปริญญาตรี สาขาเศรษฐศาสตร์ มหาวิทยาลัยเกษตรศาสตร์",
        ],
        current: [
          {
            year: "2560 - ปัจจุบัน",
            position: [
              "กรรมการและกรรมการอิสระ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ รีท จำกัด",
            ],
          },
          {
            year: "2556 - ปัจจุบัน",
            position: [
              "กรรมการอิสระ / ประธานกรรมการตรวจสอบ / คณะกรรมการบริหารความเสี่ยง / คณะกรรมการสรรหาและพิจารณาค่าตอบแทน บริษัท มาลีกรุ๊ป จำกัด (มหาชน)",
            ],
          },
        ],
      },
      {
        name: "virapan",
        edu: [
          "ปริญญาโท วิทยาศาสตร์มหาบัณฑิต สาขาวิศวกรรมไฟฟ้า University of Colorado ประเทศสหรัฐอเมริกา (ทุนการศึกษาจาก IBM)",
          "ปริญญาตรี วิทยาศาสตร์บัณฑิต สาขาวิศวกรรมไฟฟ้าและวิทยาศาสตร์คอมพิวเตอร์ University of Colorado ประเทศสหรัฐอเมริกา (เกียรตินิยมอันดับ 1)",
          "Director Certification Program สมาคมส่งเสริมสถาบันกรมการบริษัทไทย",
          "Audit Committee Program สมาคมส่งเสริมสถาบันกรรมการบริษัทไทย",
          "Role of Chairman Program สมาคมส่งเสริมกรรมการบริษัทไทย",
        ],
        current: [
          {
            year: "2565 - ปัจจุบัน",
            position: [
              "กรรมการอิสระ / ประธานคณะกรรมการ บริษัท นิวทริชชั่นโปรเฟส จำกัด",
              "กรรมการ บริษัท นิคมอุตสาหกรรมเอเซีย จำกัด",
            ],
          },
          {
            year: "2564 - ปัจจุบัน",
            position: ["ที่ปรึกษาประธาน บริษัท นิคมอุตสาหกรรมเอเซีย จำกัด"],
          },
          {
            year: "2561 - ปัจจุบัน",
            position: [
              "ประธานคณะกรรมการ / กรรมการอิสระ / ประธานกรรมการสรรหาและพิจารณาค่าตอบแทน บริษัท นีโอ คอร์ปอเรท จำกัด",
              "กรรมการอิสระ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ รีท จำกัด",
            ],
          },
        ],
      },
      {
        name: "voranon",
        edu: [
          "ปริญญาโท วิทยาศาสตร์มหาบัณฑิต สาขาธุรกิจอสังหาริมทรัพย์ มหาวิทยาลัยธรรมศาสตร์",
        ],
        current: [
          {
            year: "2565 - ปัจจุบัน",
            position: [
              "กรรมการ / กรรมการผู้จัดการ บริษัท ดุสิตธานี พร็อพเพอร์ตี้ส์ รีท จำกัด",
              "กรรมการ บริษัท ดุสิตธานี รีท จำกัด",
              "กรรมการ บริษัท ดุสิต มัลดีฟส์ อินเวสเม้นท์ จำกัด",
            ],
          },
        ],
      },
    ],
  },
];

export default BoardData;
