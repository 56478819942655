import React, { useEffect, useState, createContext } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import Monthname from "../../components/monthname";

import OccChart from "./OccChart";
import RevChart from "./RevChart";
import NationChart from "./NationChart";

export default function Performace() {
  const { t, i18n } = useTranslation("management", {
    keyPrefix: "performance",
  });

  useEffect(() => {
    document.title = "DREIT Buy Back - Asset Performance";
  }, []);

  const [year, setYear] = useState([]);

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_pfm;

  const getYear = async () => {
    const res = await Axios.get(url + "/getYears").then((r) => setYear(r.data));
  };

  useEffect(() => {
    getYear();
  }, []);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);

  const [period, setPeriod] = useState("all");
  const [type, setType] = useState("occ");

  const getData = async () => {
    const [res1, res2, res3, res4] = await Promise.all([
      Axios.get(url + "/getPerformance/" + period + "/ABCT"),
      Axios.get(url + "/getPerformance/" + period + "/ABST"),
      Axios.get(url + "/getNation/" + period + "/ABCT"),
      Axios.get(url + "/getNation/" + period + "/ABST"),
    ]);
    let data1 = res1.data;
    let data2 = res2.data;
    if (period != "all") {
      data1.map((d) => (d.month = Monthname[d.month - 1][`s${i18n.language}`]));
      data2.map((d) => (d.month = Monthname[d.month - 1][`s${i18n.language}`]));
    }

    setData1(data1.reverse());
    setData2(data2.reverse());
    setData3(res3.data.reverse());
    setData4(res4.data.reverse());
  };

  useEffect(() => {
    getData();
  }, [period, i18n.language]);

  useEffect(() => {
    console.log(data3);
  }, [data3]);

  return (
    <section
      id="AssetPerformance"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-10 w-full text-center sm:mb-20 lg:mb-32">
        <h1 className="text-3xl font-semibold text-gold">{t("title")}</h1>
      </div>
      <div className="mb-5 grid max-md:gap-4 sm:mb-10 md:grid-cols-2 lg:mb-16">
        <div className="flex w-full items-center gap-3 md:w-3/4">
          <label htmlFor="cmbtype" className="min-w-fit">
            {t("info")}:
          </label>
          <select
            className="h-8 w-full appearance-none rounded-none border-b-2 border-gold/60 px-3 focus:outline-none focus:ring focus:ring-gold"
            id="cmbtype"
            onChange={(e) => setType(e.target.value)}
          >
            <option value="occ">{t("infoS1")}</option>
            <option value="rev">{t("infoS2")}</option>
          </select>
        </div>
        <div className="flex w-full items-center gap-3 md:w-3/4">
          <label htmlFor="cmbtime" className="min-w-fit">
            {t("time")}:
          </label>
          <select
            className="h-8 w-full appearance-none rounded-none border-b-2 border-gold/60 px-3 focus:outline-none focus:ring focus:ring-gold"
            id="cmbtime"
            onChange={(e) => setPeriod(e.target.value)}
          >
            <option value="all">{t("timeS1")}</option>
            <option value="month">{t("timeS2")}</option>
            {year.map((y, i) => (
              <option value={y.year} key={i}>
                {y.year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mb-5 grid max-lg:gap-4 sm:mb-10 lg:mb-16 lg:grid-cols-2">
        <div>
          <h3 className="text-2xl font-medium text-blueD">{t("chinatown")}</h3>
          {type == "occ" ? (
            <OccChart data={data1} timespan={period} />
          ) : (
            <RevChart data={data1} timespan={period} />
          )}
          <NationChart data={data3} />
        </div>
        <div>
          <h3 className="text-2xl font-medium text-blueD">{t("sathorn")}</h3>
          {type == "occ" ? (
            <OccChart data={data2} timespan={period} />
          ) : (
            <RevChart data={data2} timespan={period} />
          )}
          <NationChart data={data4} />
        </div>
      </div>

      {/*------------------------------------*/}
    </section>
  );
}
