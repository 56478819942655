import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Lease() {
  const { t } = useTranslation("management", { keyPrefix: "lease" });

  useEffect(() => {
    document.title = "DREIT Buy Back - Lessee and Hotel Management";
  }, []);
  return (
    <section
      id="Lease"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-56 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-10 w-full text-center sm:mb-20 lg:mb-32">
        <h1 className="text-3xl font-semibold">{t("title")}</h1>
        <img
          src={require("./img/lease_banner.jpg")}
          alt="Reit structure"
          className="mx-auto my-4 h-80 w-full object-cover sm:my-7 lg:my-14"
        />
      </div>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-4">
        <div className="mx-auto w-[45%] md:mx-0 md:w-4/5 lg:w-4/6">
          <img
            src={require("./img/asai_logo.png")}
            alt="as-logo"
            className="w-full object-contain"
          />
        </div>
        <div>
          <h2 className="text-3xl font-semibold">{t("infoD1")}</h2>
          <p className="mt-2">{t("Desc1")}</p>
        </div>
      </div>
      <div className="col-start-2 mt-6 bg-[#FFF8ED] p-2 sm:p-5 md:mt-12 lg:p-8">
        <div className="flex flex-col">
          {Array.from({ length: 6 }).map((_, i) => (
            <div
              key={i}
              className="border-b border-dashed border-[#A4A4A4] pb-4 pt-4 first:pt-0 last:border-none last:pb-0"
            >
              <h3 className="text-2xl font-semibold text-blueD">
                {t("infoT" + (i + 1))}
              </h3>
              <p className="text-lg font-medium">{t("infoD" + (i + 1))}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-8 md:mt-12 md:grid-cols-2 md:gap-4">
        <div className="mx-auto w-[45%] md:mx-0 md:w-4/5 lg:w-4/6">
          <img
            src={require("./img/dusit_logo.png")}
            alt="as-logo"
            className="w-full object-contain"
          />
        </div>
        <div>
          <h2 className="text-3xl font-semibold">{t("infoD11")}</h2>
          <p className="mt-2">{t("Desc2")}</p>
        </div>
      </div>
      <div className="col-start-2 mt-6 bg-[#FFF8ED] p-2 sm:p-5 md:mt-12 lg:p-8">
        <div className="flex flex-col">
          {Array.from({ length: 6 }).map((_, i) => (
            <div
              key={i}
              className="border-b border-dashed border-[#A4A4A4] pb-4 pt-4 first:pt-0 last:border-none last:pb-0"
            >
              <h3 className="text-2xl font-semibold text-blueD">
                {t("infoT" + (i + 1) + "1")}
              </h3>
              <p className="text-lg font-medium">
                {t("infoD" + (i + 1) + "1")}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/*------------------------------------*/}
    </section>
  );
}
