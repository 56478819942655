import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Herocard(props) {
  const [data, setData] = useState({});

  const { i18n } = useTranslation();

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <div
      id="hero-card"
      className="flex h-[300px] w-full max-w-[348px] flex-col items-center justify-between bg-white p-8 shadow-md"
    >
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="text-4xl text-gold">{data.icon}</div>

        <div className="text-2xl text-blueD">{data.title}</div>
      </div>
      <div>
        <h5
          className={`text-center ${data.download && i18n.language == "th" ? "text-4xl" : "text-5xl"} font-medium text-blueD`}
        >
          {data.value}
        </h5>
      </div>
      <div>
        <h6 className={`text-xl text-gray-500`}>
          {!data.download ? (
            data.update
          ) : (
            <a
              href="https://market.sec.or.th/public/ipos/IPOSEQ01.aspx?TransID=614036&lang=th"
              /*href="https://dreitbb.blob.core.windows.net/filing/DREITBB_Full.pdf" download="DREITBB_Full.pdf"*/
              className="flex cursor-pointer items-center gap-4"
            >
              <div>{data.update}</div>
              <div>{data.dwtext}</div>
            </a>
          )}
        </h6>
      </div>
    </div>
  );
}
