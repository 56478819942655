/* Description
d - is sql return date format
mt - "s" for convert to short month name "f" for convert to full month name*/
import Monthname from "./monthname";

function CorrectDate(d, mt, format, lang) {
  let dd = "";
  let mm = "";
  let yy = "";

  if (d.indexOf("T") !== -1) {
    d = d.substring(0, d.indexOf("T"));
  }

  yy = d.substring(0, d.indexOf("-"));
  let mi = d.substring(d.indexOf("-") + 1, d.indexOf("-") + 3);
  dd = d.substring(d.length - 2);

  switch (mt) {
    case "s":
      mm =
        lang == "en"
          ? Monthname[Number(mi) - 1].sen
          : Monthname[Number(mi) - 1].sth;
      break;
    case "f":
      mm =
        lang == "en"
          ? Monthname[Number(mi) - 1].en
          : Monthname[Number(mi) - 1].th;
      break;
    case "n":
      mm = mi;
      break;
    default:
      mm = mi;
  }
  if (lang == "th") {
    yy = Number(yy) + 543;
  }

  switch (format) {
    case "mm/dd/yyyy":
      return mm + "/" + dd + "/" + yy;
    case "yyyy-mm-dd":
      return yy + "-" + mm + "-" + dd;
    default:
      return dd + " " + mm + " " + yy;
  }
}

export default CorrectDate;
