import React, { useState, useEffect } from "react";
import SubNavItems from "./SubNavItems";
import { useTranslation } from "react-i18next";
import { use } from "i18next";

export default function SubNavMenu(props) {
  const { i18n } = useTranslation();
  const [nav, setNav] = useState([]);
  const [lng, setLng] = useState(i18n.language);

  useEffect(() => {
    if (props.main != "") {
      setNav(
        SubNavItems.filter((item) => item.main === props.main).map(
          (item) => item.sub,
        )[0],
      );
    }
  }, [props.main]);

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  return (
    <div id="subnavmenu" className="h-fit w-full">
      {nav.map((n, i) => (
        <div>
          <div key={i} className="mb-3 border-b border-gold text-lg text-gold">
            {n[lng]}
          </div>
          <ul>
            {n.subnav.map((s, x) => (
              <li
                key={x}
                className="pb-3 pl-3 hover:font-semibold hover:text-gold"
              >
                <a href={s.path}>{s[lng]}</a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
