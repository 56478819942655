import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";

export default function ContactForm(props) {
  const { t } = useTranslation("translation", { keyPrefix: "contact" });

  const initContact = {
    name: "",
    surname: "",
    phone: "",
    email: "",
    message: "",
    accept: false,
  };

  const [contact, setContact] = useState(initContact);

  const [phoneFormat, setPhoneFormat] = useState(true);
  const [emailFormat, setEmailFormat] = useState(true);

  const phoneVerify = () => {
    if (contact.phone != "") {
      let check = contact.phone.match(/^\d{10}$/);
      setPhoneFormat(check);
    } else {
      setPhoneFormat(true);
    }
  };

  const emailVerify = () => {
    if (contact.email != "") {
      let check = contact.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
      setEmailFormat(check);
    } else {
      setEmailFormat(true);
    }
  };

  useEffect(() => {
    phoneVerify();
  }, [contact.phone]);

  useEffect(() => {
    emailVerify();
  }, [contact.email]);

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_ctc;

  const submitContact = () => {
    if (!contact.accept) {
      alert(t("acceptCheck"));
      return;
    }
    if (!phoneFormat || !emailFormat) {
      alert(t("contactCheck"));
      return;
    }
    const res = Axios.post(url + "/sendContact", contact).then((r) => {
      if (r.status === 200) {
        setContact(initContact);
      } else {
        alert("Error sending message");
      }
    });
  };

  useEffect(() => {
    console.log(contact);
  }, [contact]);

  return (
    <div id="contactform">
      <div className="w-full bg-gray-200 p-4">
        <div
          className="mb-4 text-center"
          dangerouslySetInnerHTML={{ __html: t("info") }}
        ></div>
        <div className="grid gap-4 lg:grid-cols-2">
          <div>
            <label className="font-semibold" htmlFor="nametxt">
              {t("name")}
            </label>
            <input
              type="text"
              id="nametxt"
              className="w-full rounded-md px-2 py-1"
              value={contact.name}
              onChange={(e) => setContact({ ...contact, name: e.target.value })}
            />
          </div>
          <div>
            <label className="font-semibold" htmlFor="surnametxt">
              {t("surname")}
            </label>
            <input
              type="text"
              id="surnametxt"
              className="w-full rounded-md px-2 py-1"
              value={contact.surname}
              onChange={(e) =>
                setContact({ ...contact, surname: e.target.value })
              }
            />
          </div>
          <div>
            <label className="font-semibold" htmlFor="phonetxt">
              {t("tel")}
            </label>
            <input
              type="text"
              id="phonetxt"
              className="w-full rounded-md px-2 py-1"
              value={contact.phone}
              onChange={(e) => {
                setContact({ ...contact, phone: e.target.value });
              }}
            />
            <span className={`text-red-500 ${phoneFormat ? "hidden" : ""}`}>
              {t("phonefill")}
            </span>
          </div>
          <div>
            <label className="font-semibold" htmlFor="emailtxt">
              {t("email")}
            </label>
            <input
              type="email"
              id="emailtxt"
              className="w-full rounded-md px-2 py-1"
              value={contact.email}
              onChange={(e) => {
                setContact({ ...contact, email: e.target.value });
              }}
            />
            <span className={`text-red-500 ${emailFormat ? "hidden" : ""}`}>
              {t("emailfill")}
            </span>
          </div>
          <div className="lg:col-span-2">
            <label className="font-semibold" htmlFor="msgltxt">
              {t("msg")}
            </label>
            <textarea
              id="msgtxt"
              className="h-40 w-full rounded-md px-2 py-1"
              row={5}
              value={contact.message}
              onChange={(e) =>
                setContact({ ...contact, message: e.target.value })
              }
            ></textarea>
          </div>
        </div>
      </div>
      <div className="my-4 flex items-start gap-2">
        <input
          type="checkbox"
          id="agree"
          className="size-5 accent-gold"
          checked={contact.accept}
          onChange={(e) => setContact({ ...contact, accept: !contact.accept })}
        />
        <label htmlFor="agree">{t("accept")}</label>
      </div>
      <div className="mb-4 flex justify-end md:mb-8">
        <button
          className="rounded-lg border border-gold bg-gold px-10 py-2 text-white hover:bg-white hover:text-gold"
          onClick={submitContact}
        >
          {t("submit")}
        </button>
      </div>
    </div>
  );
}
