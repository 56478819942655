import React, { useEffect, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";
import Bctranslations from "./bctrans";
import { useTranslation } from "react-i18next";

const Breadcrumb = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [breadcrumbHTML, setBreadcrumbHTML] = useState("");

  useEffect(() => {
    const path = location.pathname.split("/").filter((part) => part);

    // Object ที่เก็บการแปลของแต่ละ path
    const translations = Bctranslations;

    // เลือกภาษาที่ต้องการแสดง
    const language = i18n.language; // เปลี่ยนเป็น "en" สำหรับภาษาอังกฤษ

    let breadcrumbHTML = "";
    let accumulatedPath = "";

    breadcrumbHTML += `<li class="breadcrumb-item flex gap-4 items-center max-md:hidden"><a href="/" class="cursor-pointer">${translations["home"][language]}</a><div>></div></li>`;

    path.forEach((part, index) => {
      accumulatedPath += `/${part}`;
      const translatedPart = translations[part]
        ? translations[part][language]
        : part;
      if (index === path.length - 1) {
        breadcrumbHTML += `<li class="breadcrumb-item bg-gold px-4 rounded-full text-white max-md:hidden" aria-current="page">${translatedPart}</li>`;
      } else {
        breadcrumbHTML += `<li class="breadcrumb-item flex gap-4 items-center"><a href=${accumulatedPath} class="cursor-pointer">${translatedPart}</a><div>${">"}</div></li>`;
      }
    });

    setBreadcrumbHTML(breadcrumbHTML);
  }, [location, i18n.language]);

  return (
    <div>
      <nav
        aria-label="breadcrumb"
        className="fixed z-[9998] w-full bg-[#F2E5CF] 2xl:container"
      >
        <ol
          className="breadcrumb flex gap-4 px-5 py-2 text-gray-500 sm:px-20 lg:px-32"
          dangerouslySetInnerHTML={{ __html: breadcrumbHTML }}
        ></ol>
      </nav>
      <Outlet />
    </div>
  );
};

export default Breadcrumb;
