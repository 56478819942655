import React from "react";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function Asset() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "home.asset",
  });

  const nav = useNavigate();

  const cnClick = () => {
    nav("/corporate/investment/chinatown");
  };

  const stClick = () => {
    nav("/corporate/investment/sathorn");
  };

  return (
    <section id="asset-home" className="py-16 sm:py-24">
      <div className="w-full px-5 text-center sm:px-20 lg:px-32">
        <h3 className="text-4xl text-gray-500">{t("title")}</h3>
        <p className="mt-4 text-gray-500">{t("desc")}</p>
      </div>
      <div className="mt-24 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:gap-4 lg:px-32">
        <div className="aspect-h-1 aspect-w-1 lg:aspect-w-2 lg:col-span-2">
          <img
            src={require("./img/asset/asset1.jpg")}
            alt="Asset"
            className="object-cover"
          />
        </div>
        <div className="flex h-[384px] w-full flex-col items-center justify-between bg-gold p-6 sm:h-full">
          <h2 className="flex h-full w-full items-center text-center font-Petrona text-5xl font-medium text-white md:text-6xl lg:text-4xl xl:text-6xl">
            {t("asset1")}
          </h2>

          <button
            className="flex w-full items-center justify-center gap-4 border border-white bg-white py-3 text-gold hover:bg-gold hover:text-white"
            onClick={cnClick}
          >
            {t("info")} <MdKeyboardArrowRight />
          </button>
        </div>
        <div className="flex h-[384px] w-full flex-col items-center justify-between bg-gold p-6 max-sm:order-last sm:h-full">
          <h2 className="flex h-full w-full items-center text-center font-Petrona text-5xl font-medium text-white md:text-6xl lg:text-4xl xl:text-6xl">
            {t("asset2")}
          </h2>
          <button
            className="flex w-full items-center justify-center gap-4 border border-white bg-white py-3 text-gold hover:bg-gold hover:text-white"
            onClick={stClick}
          >
            {t("info")} <MdKeyboardArrowRight />
          </button>
        </div>
        <div className="aspect-h-1 aspect-w-1 lg:aspect-w-2 lg:col-span-2">
          <img
            src={require("./img/asset/asset2.jpg")}
            alt="Asset"
            className="object-cover"
          />
        </div>
      </div>
    </section>
  );
}
