import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";

export default function Faq(props) {
  const { t, i18n } = useTranslation("ir", { keyPrefix: "resource.faq" });
  const [data, setData] = useState([]);
  const populateFaqData = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        process.env.REACT_APP_faq +
        "/getFaq/" +
        i18n.language,
    ).then((res) => {
      const dat = res.data.map((d, i) => {
        d.active = false;
        return d;
      });
      setData(dat);
    });
  };

  const [fCat, setFCat] = useState([]);
  const populateFaqCat = async () => {
    const response = await Axios.get(
      process.env.REACT_APP_API_URI +
        process.env.REACT_APP_faq +
        "/getCat/" +
        i18n.language,
    ).then((res) => {
      setFCat(res.data);
    });
  };

  useEffect(() => {
    populateFaqCat();
    populateFaqData();
  }, [i18n.language]);

  useEffect(() => {
    //console.log(data);
  }, [fCat]);

  const showAns = (index) => {
    const dat = data.map((d, i) => {
      if (i == index) {
        if (!d.active) {
          d.active = true;
        } else {
          d.active = false;
        }
      } else {
        d.active = false;
      }
      return d;
    });
    setData(dat);
  };

  const [mousePos, setMousePos] = useState({});

  useEffect(() => {
    document.title = "DREIT Buy Back - FAQs";
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const mouse = "top-[" + mousePos.y + "px]" + " left-[" + mousePos.x + "px]";

  return (
    <section
      id="FAQs"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-5 w-full sm:mb-10 lg:mb-24">
        <h1 className="text-3xl font-semibold text-gold">{t("title")}</h1>
      </div>
      <div>
        <p className="mt-4 w-full text-gray-500 md:text-xl">{t("desc")}</p>
      </div>
      {fCat.map((c, i) => (
        <div key={i} className="my-5">
          <div className="mb-5 border-b-4 border-gold text-xl font-medium text-gold md:w-2/3">
            {c.cat}
          </div>

          {data.map(
            (a, i) =>
              a.cat == c.id && (
                <div key={i} className="mb-10">
                  <div className="flex w-full items-center justify-between rounded-xl bg-gold px-4 py-2 text-white md:w-2/3 md:text-xl">
                    {a.question}
                    <div
                      className="flex h-4 w-4 cursor-pointer items-center justify-center md:h-6 md:w-6"
                      onClick={() => showAns(i)}
                    >
                      <div
                        className={`h-1 w-4 bg-white md:w-6 ${
                          a.active == true ? "opacity-0" : ""
                        } transition duration-300`}
                      ></div>
                      <div
                        className={`h-[14px] w-1 -translate-x-2 bg-white md:h-5 md:-translate-x-3 ${
                          a.active == true ? "h-[16px] rotate-90" : ""
                        } transition duration-300`}
                      ></div>
                    </div>
                  </div>
                  <div
                    className={`pl-2 pt-3 md:pl-8 ${
                      a.active == true ? "" : "hidden"
                    }`}
                    dangerouslySetInnerHTML={{ __html: a.answer }}
                  />
                </div>
              ),
          )}
        </div>
      ))}
    </section>
  );
}
