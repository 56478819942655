const SubNavItems = [
  {
    main: "overview",
    sub: [
      {
        th: "เกี่ยวกับกองทรัสต์",
        en: "Reit Overview",
        subnav: [
          {
            th: "เกี่ยวกับ DreitBB",
            en: "About DreitBB",
            path: "/corporate/reit/about",
          },
          {
            th: "โครงสร้างกองทรัสต์",
            en: "Trust Structure",
            path: "/corporate/reit/structure",
          },
          {
            th: "นโยบายการลงทุน",
            en: "Investment Policy",
            path: "/corporate/reit/investmentpolicy",
          },
        ],
      },
      {
        th: "การบริหารจัดการ",
        en: "Management",
        subnav: [
          {
            th: "ผู้จัดการกองทรัสต์",
            en: "Reit Manager",
            path: "/corporate/management/manager",
          },
          {
            th: "โครงสร้างองค์กร",
            en: "Reit Structure",
            path: "/corporate/management/reitstructure",
          },
          {
            th: "คณะกรรมการบริษัท",
            en: "Board of Directors",
            path: "/corporate/management/board",
          },
          {
            th: "ผู้เช่าและผู้บริหารโรงแรม",
            en: "Lessee and Hotel Management",
            path: "/corporate/management/lease",
          },
          {
            th: "ทรัสตี",
            en: "Trustee",
            path: "/corporate/management/trustee",
          },
        ],
      },
      {
        th: "สินทรัพย์ที่ลงทุน",
        en: "Investment Properties",
        subnav: [
          {
            th: "อาศัย กรุงเทพฯ ไซน่าทาวน์",
            en: "Asai Bangkok Chinatown",
            path: "/corporate/investment/chinatown",
          },
          {
            th: "อาศัย กรุงเทพฯ สาทร",
            en: "Asai Bangkok Sathorn",
            path: "/corporate/investment/sathorn",
          },

          {
            th: "ผลการดำเนินงานของอสังหาริมทรัพย์",
            en: "Asset Performance",
            path: "/corporate/investment/performance",
          },
        ],
      },
    ],
  },
  {
    main: "ir",
    sub: [
      {
        th: "ข้อมูลการเงิน",
        en: "Financial Information",
        subnav: [
          {
            th: "ราคา NAV",
            en: "Unit NAV",
            path: "/ir/finance/nav",
          },
          /*
          {
            th: "รายงานงบการเงิน",
            en: "Financial Statement",
            path: "#",
          },
          {
            th: "รายงานประเมินมูลค่าสินทรัพย์",
            en: "Appriasal Report",
            path: "#",
          },*/
        ],
      },
      /*
      {
        th: "ข้อมูลผู้ถือหุ้น",
        en: "Shareholder Information",
        subnav: [
          {
            th: "ข้อมูลการจ่ายเงินปันผล",
            en: "Dividend History",
            path: "#",
          },
          {
            th: "การประชุมผู้ถือหุ้น",
            en: "Annual General Meeting",
            path: "#",
          },
        ],
      },
      */
      {
        th: "เอกสารเผยแพร่",
        en: "Publications",
        subnav: [
          /*
          {
            th: "รายงานประจำปี",
            en: "Annual Report",
            path: "#",
          },
          {
            th: "ฟอร์ม 56-REIT",
            en: "56-REIT Form",
            path: "#",
          },
          */
          {
            th: "ข่าวเผยแพร่",
            en: "News Release",
            path: "/ir/publications/news",
          },
          /*
          {
            th: "กิจกรรมและการนำเสนอ",
            en: "Event and Presentation",
            path: "#",
          },
          */
        ],
      },
      {
        th: "แหล่งข้อมูลสำหรับนักลงทุน",
        en: "Investor Resources",
        subnav: [
          {
            th: "คำถามที่พบบ่อย",
            en: "Investor FAQs",
            path: "/ir/resources/faq",
          },
          {
            th: "ข้อมูลติดต่อ",
            en: "Contact Information",
            path: "/ir/resources/contactinfo",
          },
        ],
      },
    ],
  },
];

export default SubNavItems;
