import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";
import Monthname from "../../components/monthname";

export default function NavChart(props) {
  const { i18n } = useTranslation();
  const [pData, setPdata] = useState([]);

  useEffect(() => {
    setPdata(props.data);
  }, [props.data]);

  const [data, setData] = useState([]);

  const options = {
    title: i18n.language == "en" ? "NAV Price" : "ราคา NAV",
    legend: {
      position: "bottom",
    },

    series: {
      0: {
        type: "line",
        targetAxisIndex: 0,
        color: "#bead8f",
      },
      1: { type: "bars", targetAxisIndex: 1, color: "#13216A" },
    },
    vAxes: {
      0: {
        title: "NAV",
        gridlines: { count: 4 },
      },
      1: { title: "Value", format: "long" },
    },
    hAxis: {
      title: i18n.language == "en" ? "Month" : "เดือน",
    },
    vAxis: {
      minorGridlines: { count: 0 },
    },
    height: 400,
    tooltip: {
      isHtml: true,
      trigger: "both",
    },
  };

  const sumData = () => {
    let dat = [
      [
        "Month",
        i18n.language == "en" ? "Price" : "ราคา",
        { type: "string", role: "tooltip", p: { html: true } },
        i18n.language == "en" ? "Value" : "มูลค่า",
        { type: "string", role: "tooltip", p: { html: true } },
      ],
    ];
    pData.map((d, i) => {
      dat.push([
        i18n.language == "en"
          ? Monthname[
              d.navDate.substring(
                d.navDate.indexOf("-") + 1,
                d.navDate.indexOf("-") + 3,
              )
            ].sen
          : Monthname[
              d.navDate.substring(
                d.navDate.indexOf("-") + 1,
                d.navDate.indexOf("-") + 3,
              )
            ].sth,
        d.navValue,
        "<div style='padding:5px;color:black;'><strong>" +
          (i18n.language == "en"
            ? Monthname[
                d.navDate.substring(
                  d.navDate.indexOf("-") + 1,
                  d.navDate.indexOf("-") + 3,
                )
              ].sen
            : Monthname[
                d.navDate.substring(
                  d.navDate.indexOf("-") + 1,
                  d.navDate.indexOf("-") + 3,
                )
              ].sth) +
          "</strong></br><p style='display:flex;'>" +
          (i18n.language == "en" ? "Price:" : "ราคา:") +
          "<strong style='margin-left:4px'>" +
          d.navValue.toFixed(2) +
          "</strong></p></div>",
        d.netAsset,
        "<div style='padding:5px;color:black;min-width:110px;'><strong>" +
          (i18n.language == "en"
            ? Monthname[
                d.navDate.substring(
                  d.navDate.indexOf("-") + 1,
                  d.navDate.indexOf("-") + 3,
                )
              ].sen
            : Monthname[
                d.navDate.substring(
                  d.navDate.indexOf("-") + 1,
                  d.navDate.indexOf("-") + 3,
                )
              ].sth) +
          "</strong></br><p style='display:flex;'>" +
          (i18n.language == "en" ? "Value:" : "มูลค่า:") +
          "<strong style='margin-left:4px'>" +
          (d.netAsset / 1000000).toLocaleString() +
          " MB" +
          "</strong></p></div>",
      ]);
    });
    setData(dat);
  };

  useEffect(() => {
    sumData();
  }, [pData, i18n.language]);

  return (
    <Chart
      chartType="ComboChart"
      data={data}
      options={options}
      width={"100%"}
    />
  );
}
