import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";

import { IoLocationOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";

import ModalPic from "./modalPic";

export default function Chinatown() {
  const { t } = useTranslation("management", { keyPrefix: "chinatown" });

  useEffect(() => {
    document.title = "DREIT Buy Back - Bangkok Chinatown";
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    //console.log(screenWidth);
  }, [screenWidth]);

  const [isSlide, setIsSlide] = useState(false);

  const closeModal = () => {
    setIsSlide(false);
  };

  return (
    <section
      id="chinatown-hotel"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      <div className="mb-10 grid grid-cols-1 gap-2 sm:mb-20 lg:mb-32">
        <div className="grid grid-cols-1 gap-2 max-md:mb-4 md:grid-cols-[1fr_10fr_1fr]">
          <div className="col-start-2 mb-8 text-center lg:mb-16">
            <h1 className="text-3xl font-semibold text-gold">{t("title")}</h1>
          </div>
          <div className="col-start-2 grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-4 lg:grid-cols-[2fr_3fr]">
            <div className="w-full md:aspect-h-1 md:aspect-w-1 max-md:h-fit">
              <img
                src={require("./img/china/chinatown1.jpg")}
                alt="chinatown1"
                className="w-full object-cover max-md:max-h-[240px]"
              />
            </div>
            <div className="grid grid-cols-4 gap-2 md:grid-cols-2 md:gap-4 lg:grid-cols-3">
              {Array.from({ length: screenWidth <= 784 ? 4 : 6 }).map(
                (_, i) => (
                  <div className="aspect-h-1 aspect-w-1 relative w-full">
                    <img
                      src={require(`./img/china/chinatown${i + 2}.jpg`)}
                      alt={`chinatown${i + 2}`}
                      className="w-full object-cover"
                    />
                    {(screenWidth <= 784 && i === 3) || i === 5 ? (
                      <div
                        className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black/50 font-medium text-white md:text-xl lg:text-2xl"
                        onClick={() => setIsSlide(true)}
                      >
                        {i === 3 ? "18+" : "16+"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Info */}

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-4 md:gap-y-8">
        {/* Info sec main */}
        <div className="grid gap-2 md:grid-cols-[1fr_5fr] md:gap-4">
          <div className="md:col-start-2">
            <div className="mb-4 flex justify-center">
              <img
                src={require("./img/logo_cn.png")}
                alt="logo_chinatown"
                className="w-1/2 object-contain md:w-3/5"
              />
            </div>
            <p>{t("desc")}</p>
          </div>
        </div>
        {/* Info sec general */}
        <div className="grid grid-cols-1 gap-2 max-md:order-3 md:gap-4 lg:grid-cols-[1fr_5fr]">
          <div className="lg:col-start-2">
            <div className="text-center">
              <h2 className="text-3xl font-medium text-gold">{t("info")}</h2>
            </div>
            <div className="bg-[#fff8ed] p-4 md:p-8">
              {Array.from({ length: 3 }).map((_, i) => (
                <div
                  className="border-b border-dashed border-[#a4a4a4] py-4 last:border-none"
                  key={i}
                >
                  <h3 className="text-2xl font-medium text-blueD">
                    {t(`infoT${i + 1}`)}
                  </h3>
                  <p className="text-gray-500">
                    {t(`infoD${i + 1}${i == 1 ? "1" : ""}`)}
                  </p>
                  {i == 1 && <p className="text-gray-500">{t(`infoD22`)}</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Info sec hilight */}
        <div className="grid grid-cols-1 gap-2 max-md:order-2 md:grid-cols-[1fr_5fr] md:gap-4">
          <div className="flex flex-col gap-8 md:col-start-2">
            <div className="flex w-full justify-between max-lg:flex-wrap max-lg:gap-2">
              {Array.from({ length: 4 }).map((_, i) => (
                <div className="flex h-28 w-full flex-col items-center justify-between border-r-2 border-gold pl-5 pr-5 last:border-r-0 max-lg:basis-[45%] max-lg:border-r-0 max-lg:first:border-b-2 lg:first:pl-0 max-lg:[&:nth-child(2)]:border-b-2">
                  <img
                    src={require(`./img/atract_cn${i + 1}.png`)}
                    alt={`atract_cn${i + 1}`}
                    className="w-12 object-contain"
                  />
                  <p className="text-center text-gold">{t(`hl${i + 1}`)}</p>
                </div>
              ))}
            </div>
            <div className="flex justify-center md:justify-start">
              <div className="w-[250px] border-2 border-gold bg-gold py-3 text-center text-lg font-semibold text-white hover:bg-white hover:text-gold">
                <a
                  href="https://www.asaihotels.com/property_offer/bangkok-chinatown/"
                  className="cursor-pointer"
                  target="_blank"
                >
                  {t("booking")}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Info sec map */}
        <div className="grid grid-cols-1 gap-2 max-md:order-4 md:gap-4 lg:grid-cols-[1fr_5fr]">
          <div className="lg:col-start-2">
            <div className="bg-[#fff8ed] p-4 md:p-8">
              <div id="map" className="mb-4 h-[200px] w-full">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCIieFzAkVbQO3qqA8qUcVKy2MlHzWtsiw",
                  }}
                  defaultCenter={{
                    lat: 13.741603081540402,
                    lng: 100.51117608465813,
                  }}
                  defaultZoom={16}
                >
                  <FaLocationDot
                    lat={13.741603081540402}
                    lng={100.51117608465813}
                    className="text-2xl text-red-500"
                  />
                </GoogleMapReact>
              </div>

              <div className="flex justify-start gap-4">
                <div className="text-3xl text-gold">
                  <IoLocationOutline />
                </div>
                <div className="flex flex-col items-start gap-3">
                  <h4 className="font-semibold text-gray-500">{t("infoT4")}</h4>
                  <p className="text-gray-500">{t("infoD4")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <ModalPic place="chinatown" show={isSlide} close={closeModal} />
    </section>
  );
}
