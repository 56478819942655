import React from "react";
import { useTranslation } from "react-i18next";

import { MdOutlineChevronRight } from "react-icons/md";

export default function About() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "home.about",
  });
  return (
    <section id="about-home" className="my-24">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="flex w-full justify-center">
          <div className="h-[248px] sm:h-[350px] sm:w-full lg:h-[483px] lg:w-2/3">
            <img
              src={require("./img/about/about.png")}
              alt="AboutUs"
              className="h-full w-full object-cover object-right"
            />
          </div>
        </div>
        <div className="flex flex-col max-lg:mt-14 max-lg:items-center max-lg:gap-10 lg:justify-between">
          <div className="flex-col items-center max-lg:flex">
            <h2 className="pb-5 text-2xl font-semibold text-gray-500 sm:text-6xl">
              {t("title")}
            </h2>
            <p className="w-[90%] font-medium text-gray-500 sm:w-4/6">
              {t("desc")}
            </p>
          </div>
          <div className="flex w-fit items-center gap-4 border border-gold bg-gold px-20 py-4 font-medium text-white hover:bg-white hover:text-gold">
            <a href="/corporate/reit/about">{t("aboutus")}</a>
            <MdOutlineChevronRight className="text-xl" />
          </div>
        </div>
      </div>
    </section>
  );
}
