import React, { Component } from "react";
import NavMenu from "./NavMenu";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

export default function Layout(props) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`mx-auto 2xl:container ${
        i18n.language == "en" ? "font-Petrona" : "font-IBM"
      }`}
    >
      <NavMenu />
      <div className="pt-[80px]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
