import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";

import ContactForm from "./contactform";

import GoogleMapReact from "google-map-react";

import { FaLocationDot } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { LiaFaxSolid } from "react-icons/lia";
import { CiMail } from "react-icons/ci";
import line from "./img/line.svg";

export default function Contact(props) {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "contact" });

  useEffect(() => {
    document.title = "DREIT Buy Back - Contact Us";
  }, []);

  const ico = [<IoLocationOutline />, <FiPhone />, <LiaFaxSolid />, <CiMail />];
  return (
    <section
      id="FAQs"
      className="px-4 py-8 text-gray-500 sm:px-20 md:py-24 lg:px-32"
    >
      {/*------------------------------------*/}
      <div className="grid place-items-center lg:grid-cols-2">
        <div>
          <ContactForm />
        </div>
        <div className="w-3/4 max-lg:order-first">
          <div className="mb-4 text-center text-3xl font-semibold">
            {t("contact")}
          </div>
          <div className="flex flex-col gap-4">
            {Array.from({ length: 5 }).map((_, i) => (
              <div>
                <div className="grid grid-cols-[50px_1fr]">
                  <div className="text-3xl text-gold">
                    {i < 4 ? (
                      ico[i]
                    ) : (
                      <img src={line} className="w-[60%] object-contain" />
                    )}
                  </div>
                  <div className="text-lg font-medium">{t(`conT${i + 1}`)}</div>

                  <div className="col-start-2">{t(`conD${i + 1}`)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <h3 className="mb-6 text-xl font-medium text-gold">{t("map")}</h3>
        <div id="map" className="mb-4 h-[240px] w-full">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCIieFzAkVbQO3qqA8qUcVKy2MlHzWtsiw",
            }}
            defaultCenter={{
              lat: 13.733591757991304,
              lng: 100.53048665767162,
            }}
            defaultZoom={16}
          >
            <FaLocationDot
              lat={13.733591757991304}
              lng={100.53048665767162}
              className="text-2xl text-red-500"
            />
          </GoogleMapReact>
        </div>
      </div>
    </section>
  );
}
