import { use } from "i18next";
import React, { useEffect, useState } from "react";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { FaCircleChevronRight } from "react-icons/fa6";

export default function ModalPic(props) {
  const place = props.place;

  const [pos, setPos] = useState(0);

  const picSelect = (p) => {
    setPos(p);
  };

  const [rnk, setRnk] = useState(0);
  useEffect(() => {
    const rnk = place == "chinatown" ? 23 : "sathorn" ? 32 : 0;
    setRnk(rnk);
  }, [place]);

  return (
    <div id="modalPicShow" hidden={!props.show}>
      <div
        className="fixed left-0 top-0 z-[9999] h-screen w-full bg-black/50"
        onClick={props.close}
      ></div>
      <div className="container fixed left-1/2 top-1/2 z-[10000] h-[calc(100vh-80px)] w-full -translate-x-1/2 -translate-y-1/2 overflow-hidden bg-black px-4 py-3 md:w-4/5">
        <div className="flex h-full flex-col justify-between gap-2">
          <div
            className="ml-auto cursor-pointer font-medium text-white"
            onClick={props.close}
          >
            X
          </div>
          <div className="relative h-full overflow-hidden">
            <div
              className="absolute left-[10px] top-1/2 text-3xl text-gold opacity-80"
              onClick={() => {
                setPos(pos - 1 < 0 ? rnk - 1 : pos - 1);
              }}
            >
              <FaCircleChevronLeft />
            </div>
            <div
              className="absolute right-[10px] top-1/2 text-3xl text-gold opacity-80"
              onClick={() => {
                setPos(pos + 1 >= rnk ? 0 : pos + 1);
              }}
            >
              <FaCircleChevronRight />
            </div>
            <div className="flex h-full justify-center">
              <img
                src={require(
                  `./img/${place == "chinatown" ? "china" : place}/${place}${pos + 1}.jpg`,
                )}
                className="h-full object-contain"
              />
            </div>
          </div>
          <div className="flex h-[180px] snap-x snap-proximity gap-2 overflow-x-auto overflow-y-hidden">
            {Array.from({ length: rnk }).map((_, i) => (
              <div
                className={`h-[110px] w-[110px] flex-shrink-0 ${i == pos ? "snap-center border-[6px] border-white" : ""}`}
                onClick={() => picSelect(i)}
              >
                <img
                  key={i}
                  src={require(
                    `./img/${place == "chinatown" ? "china" : "sathorn"}/${place}${i + 1}.jpg`,
                  )}
                  alt={`${place}${i + 1}`}
                  className="h-full w-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
