import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BoardData from "./boarddata";
import BoardBio from "./boardbio";

export default function Board(props) {
  const { t, i18n } = useTranslation("management", { keyPrefix: "board" });

  const initBoard = [
    {
      img: "chanin",
      name: "Chanin Donavanik",
      nameth: "ชนินทธ์ โทณวณิก",
      position: "Chairman",
      positionth: "ประธานกรรมการ",
      active: true,
    },
    {
      img: "suphajee",
      name: "Suphajee Suthumpun",
      nameth: "ศุภจี สุธรรมพันธุ์",
      position: "Director",
      positionth: "กรรมการ",
      active: false,
    },
    {
      img: "pattaneeporn",
      name: "Pattaneeporn Thienprasiddhi",
      nameth: "พัฒนีพร เธียรประสิทธิ์",
      position: "Director",
      positionth: "กรรมการ",
      active: false,
    },
    {
      img: "natharin",
      name: "Natharin Talthong",
      nameth: "ณัฐรินทร์ ตาลทอง",
      position: "Independent Director",
      positionth: "กรรมการอิสระ",
      active: false,
    },
    {
      img: "virapan",
      name: "Virapan Pulges",
      nameth: "วีรพันธ์ พูลเกษ",
      position: "Independent Director",
      positionth: "กรรมการอิสระ",
      active: false,
    },
    {
      img: "voranon",
      name: "Voranon Asavakittimetin",
      nameth: "วรนนท์ อัศวกิตติเมธิน",
      position: "Managing Director",
      positionth: "กรรมการผู้จัดการ",
      active: false,
    },
  ];

  const [boardlist, setBoardlist] = useState(initBoard);

  const boardClick = (index) => {
    let board = boardlist.map((b, i) => {
      if (i == index) {
        b.active = true;
        return b;
      } else {
        b.active = false;
        return b;
      }
    });
    setBoardlist(board);
  };

  const [lng, setLng] = useState("en");
  const [edu, setEdu] = useState([]);
  const [current, setCurrent] = useState([]);

  useEffect(() => {
    const boardlng = BoardData.filter((b) => b.lng == lng)[0].data;
    const name = boardlist.filter((b) => b.active == true)[0].img;
    const boardBio = boardlng.filter((b) => b.name == name)[0];
    setEdu(boardBio.edu);
    setCurrent(boardBio.current);
  }, [boardlist, lng]);

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    document.title = "DREIT Buy Back - Board of Directors";
  }, []);

  return (
    <section className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24">
      <div>
        <h1 className="mb-10 w-full text-center text-3xl font-medium">
          {t("title")}
        </h1>
        <div className="flex flex-wrap justify-between max-sm:flex-col">
          {boardlist.map((b, i) => (
            <div
              className={`max-md:flex max-md:flex-col sm:${b.active ? "w-full" : "w-1/2"} md:w-1/3 lg:w-1/6`}
            >
              <div
                className={`p-6 ${
                  b.active ? "bg-gold" : "hover:bg-[#cecece]"
                } h-full`}
                onClick={() => boardClick(i)}
              >
                <img
                  src={require("./img/" + b.img + ".jpg")}
                  alt={b.img}
                  className="mx-auto"
                />
                <div
                  className={`${
                    b.active ? "text-white" : "text-gray-500"
                  } mt-6 text-lg font-medium`}
                >
                  {lng == "en" ? b.name : b.nameth}
                </div>
                <div
                  className={`${
                    b.active ? "text-white" : "text-gray-500"
                  } text-lg`}
                >
                  {lng == "en" ? b.position : b.positionth}
                </div>
              </div>
              <div className={`${b.active ? "w-full md:hidden" : "hidden"} `}>
                <BoardBio edu={edu} current={current} />
              </div>
            </div>
          ))}
          <div className={`${"hidden md:block md:w-full"}`}>
            <BoardBio edu={edu} current={current} />
          </div>
        </div>
      </div>
    </section>
  );
}
