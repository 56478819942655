import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegChessQueen } from "react-icons/fa6";
import { LiaMapMarkedAltSolid } from "react-icons/lia";
import { RiTeamLine } from "react-icons/ri";
import { FiThumbsUp } from "react-icons/fi";
import { RiHandCoinLine } from "react-icons/ri";

export default function Hilight() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "home.hilight",
  });

  const cards = [
    {
      icon: <LiaMapMarkedAltSolid />,
      title: t("hl1.title"),
      text: t("hl1.desc"),
    },
    { icon: <RiTeamLine />, title: t("hl2.title"), text: t("hl2.desc") },
    { icon: <RiHandCoinLine />, title: t("hl3.title"), text: t("hl3.desc") },
    { icon: <FiThumbsUp />, title: t("hl4.title"), text: t("hl4.desc") },
  ];

  return (
    <section
      id="hilight-home"
      className="bg-gold px-5 py-16 sm:px-20 sm:py-24 lg:px-32"
    >
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grid grid-cols-[1fr_5fr] gap-y-16">
          <div className="col-start-2 flex gap-4">
            <FaRegChessQueen className="text-3xl text-white" />
            <h3 className="text-4xl text-white">{t("title")}</h3>
          </div>
          <div className="col-span-2">
            <img
              src={require("./img/hilight/hilight.png")}
              alt="Hilight"
              className="h-[324px] w-full object-cover lg:h-[515px]"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {cards.map((c, i) => (
            <div
              className="group h-[240px] w-full bg-white p-4 shadow-lg hover:bg-[#886A42] lg:h-full"
              key={i}
            >
              <div className="mb-4 flex items-center gap-4 text-gray-500 group-hover:text-white">
                <div className="text-3xl">{c.icon}</div>
                <h4 className="text-xl">{c.title}</h4>
              </div>
              <div>
                <p className="text-gray-500 group-hover:text-white">{c.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
