import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Manager() {
  const { t } = useTranslation("management", { keyPrefix: "manager" });

  useEffect(() => {
    document.title = "DREIT Buy Back - REIT Manager";
  }, []);
  return (
    <section
      id="REITmanager"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-10 grid grid-cols-1 gap-2 sm:mb-20 md:grid-cols-2 lg:mb-32">
        <div className="grid grid-cols-1 gap-2 max-md:mb-4 md:grid-cols-[1fr_5fr]">
          <div className="md:col-start-2">
            <h1 className="text-3xl font-semibold">{t("title")}</h1>
            <p className="mt-4">{t("desc")}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-[1fr_4fr_1fr]">
          <div className="flex flex-col gap-4 md:col-start-2">
            {Array.from({ length: 5 }).map((_, i) => (
              <div>
                <h2 className="text-2xl font-semibold text-gold">
                  {t("subT" + (i + 1))}
                </h2>
                <p className="text-lg font-semibold">{t("subD" + (i + 1))}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="grid gap-2 md:grid-cols-[1fr_10fr_1fr]">
        <div className="col-start-2 bg-[#FFF8ED] p-2 sm:p-5 lg:p-8">
          <div className="flex flex-col">
            {Array.from({ length: 7 }).map((_, i) => (
              <div
                key={i}
                className="border-b border-dashed border-[#A4A4A4] pb-4 pt-4 first:pt-0 last:border-none last:pb-0"
              >
                <h3 className="text-2xl font-semibold text-blueD">
                  {t("gT" + (i + 1))}
                </h3>
                <p className="text-lg font-medium">{t("gD" + (i + 1))}</p>
                {i + 1 == 4 && (
                  <ul className="list-inside list-disc">
                    <li className="text-lg font-medium">{t("gD41")}</li>
                    <li className="text-lg font-medium">{t("gD42")}</li>
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/*------------------------------------*/}
    </section>
  );
}
