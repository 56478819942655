import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ContactPoint(props) {
  const { t, i18n } = useTranslation("ir", { keyPrefix: "resource.contact" });

  useEffect(() => {
    document.title = "DREIT Buy Back - Contact Information";
  }, []);

  const logo = ["DREIT_logo", "asai_logo", "dusit_logo", "mfc_logo"];
  return (
    <section
      id="contactpoint"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-5 w-full sm:mb-10 lg:mb-24">
        <h1 className="text-3xl font-semibold text-gold">{t("title")}</h1>
      </div>

      {Array.from({ length: 4 }).map((_, i) => (
        <div className="mb-6 grid border-gold last:border-none max-lg:border-b lg:grid-cols-[1fr_4.5fr]">
          <div className="w-full border-gold lg:aspect-h-1 lg:aspect-w-1 max-lg:mb-4 max-lg:px-6 lg:flex lg:justify-center lg:border">
            <img
              src={require("./img/" + logo[i] + ".png")}
              alt={logo[i]}
              className="mx-auto h-full w-1/2 max-w-[200px] object-contain sm:w-1/3 md:w-1/4 lg:w-full lg:p-3"
            />
          </div>
          <div className="border-gold px-6 py-3 lg:border lg:border-l-0">
            <p className="text-2xl font-medium text-gold">
              {t(`conT${i + 1}`)}
            </p>
            <p className="text-lg font-medium">{t(`conCom${i + 1}`)}</p>
            <p>{t(`conAddr${i + 1}`)}</p>
            <p dangerouslySetInnerHTML={{ __html: t(`conTel${i + 1}`) }}></p>
            <p>{t(`conMail${i + 1}`)}</p>
            <p>{t(`conOther${i + 1}`)}</p>
          </div>
        </div>
      ))}
    </section>
  );
}
