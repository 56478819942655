import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Herocard from "./herocard";
import Axios from "axios";
import CorrectDate from "../components/correctDate";

import { BsBank } from "react-icons/bs";
import { IoBarChartOutline } from "react-icons/io5";
import { LuFilePieChart } from "react-icons/lu";
import { FiDownload } from "react-icons/fi";

export default function Hero() {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "home.hero" });
  const Img = [1, 2, 3, 4];
  const [current, setCurrent] = useState(0);

  const carouselScroll = () => {
    if (current == Img.length - 1) {
      return setCurrent(0);
    }
    return setCurrent(current + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      carouselScroll();
    }, 4000);
    return () => clearInterval(interval);
  });

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_n;

  const [data, setData] = useState({});
  const getData = async () => {
    const res = await Axios.get(url + "/getLast").then((r) => {
      setData(r.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const formatToM = (num) => {
    return (num / 1000000).toLocaleString() + " MB";
  };

  const initCardData = [
    {
      icon: <BsBank />,
      title: t("ctotal"),
      value: formatToM(data.netAsset),
      update:
        t("update") +
        " " +
        (Object.keys(data).length > 0 &&
          CorrectDate(data.navDate, "f", "", i18n.language)),
    },
    {
      icon: <IoBarChartOutline />,
      title: t("cnet"),
      value: Object.keys(data).length > 0 && data.navValue.toFixed(2),
      update:
        t("update") +
        " " +
        (Object.keys(data).length > 0 &&
          CorrectDate(data.navDate, "f", "", i18n.language)),
    },
    {
      icon: <LuFilePieChart />,
      title: t("cinfo"),
      value: i18n.language == "en" ? "Filing" : "เอกสารเสนอขาย",
      update: <FiDownload />,
      dwtext: t("download"),
      download: true,
    },
  ];
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    setCardData(initCardData);
  }, [i18n.language]);

  useEffect(() => {
    setCardData(initCardData);
  }, [data]);

  useEffect(() => {
    //console.log(cardData);
  }, [cardData]);

  return (
    <section id="hero-home" className="relative">
      <div
        className={`relative flex h-screen w-full overflow-hidden sm:max-h-[500px] lg:max-h-[950px]`}
      >
        {Img.map((d, i) => (
          <img
            src={require("./img/hero/slide_" + d + ".png")}
            alt="hero_img"
            id={"img" + d}
            className={`h-full min-w-full object-cover object-center transition duration-500 ease-in-out`}
            style={{ transform: `translate(-${current * 100}%)` }}
            key={i}
          />
        ))}
        <div className="absolute left-0 top-0 h-full w-[90%] bg-gradient-to-r from-black/75 to-transparent"></div>
        <div className="absolute left-[10%] top-1/4 max-md:pr-8">
          <h1 className="font-Petrona text-[56px] font-medium text-white drop-shadow-[6px_6px_6px_rgba(0,0,0,0.4)] lg:text-[64px]">
            DREITBB
          </h1>
          <h4 className="text-xl font-semibold text-white drop-shadow-[6px_6px_6px_rgba(0,0,0,0.4)]">
            {t("title")}
          </h4>
        </div>
      </div>
      <div className="lg:relative lg:-top-[180px] lg:-mb-[100px]">
        <div className="flex w-full flex-shrink-0 flex-wrap justify-center gap-4 max-lg:mt-4">
          {cardData.map((d, i) => (
            <Herocard data={d} />
          ))}
        </div>
      </div>
    </section>
  );
}
