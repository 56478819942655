import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Policy() {
  const { t } = useTranslation("corporate", { keyPrefix: "policy" });

  useEffect(() => {
    document.title = "DREIT Buy Back - Investment Policy";
  }, []);
  return (
    <section
      id="REIToverview"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-10 w-full text-center sm:mb-20 lg:mb-32">
        <h1 className="text-3xl font-semibold">{t("title")}</h1>

        <p className="mt-4">{t("desc")}</p>
      </div>
      <div className="mb-10 grid grid-cols-1 gap-4 lg:grid-cols-[1fr_4fr_5fr_1fr]">
        <div className="max-h-[200px] w-full lg:col-start-2 lg:max-h-[450px]">
          <img
            src={require("./img/as1-main.jpg")}
            alt="asai-sathon"
            className="h-full w-full object-cover max-md:object-right"
          />
        </div>
        <div className="flex flex-col justify-between lg:col-start-3">
          <div className="mb-4 h-full bg-[#FFF8ED] p-4">
            <h2 className="text-3xl font-semibold text-gold">{t("as1")}</h2>
            <p className="mt-2">{t("asd1")}</p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {Array.from({ length: 3 }).map((_, i) => (
              <div className="aspect-h-1 aspect-w-1 w-full">
                <img
                  src={require("./img/as1-pic" + (i + 1) + ".jpg")}
                  alt={`as1-pic${i + 1}`}
                  className="h-full w-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-[1fr_4fr_5fr_1fr]">
        <div className="max-h-[200px] w-full lg:col-start-2 lg:max-h-[600px]">
          <img
            src={require("./img/as2-main.jpg")}
            alt="asai-chinatown"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="flex flex-col justify-between lg:col-start-3">
          <div className="mb-4 h-full bg-[#FFF8ED] p-4">
            <h2 className="text-3xl font-semibold text-gold">{t("as2")}</h2>
            <p className="mt-2">{t("asd2")}</p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {Array.from({ length: 3 }).map((_, i) => (
              <div className="aspect-h-1 aspect-w-1 w-full">
                <img
                  src={require("./img/as2-pic" + (i + 1) + ".jpg")}
                  alt={`as2-pic${i + 1}`}
                  className="h-full w-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-10 grid grid-cols-1 gap-4 lg:grid-cols-[1fr_10fr_1fr]">
        <div className="bg-[#fff8ed] p-8 lg:col-start-2">
          <h2 className="mb-4 text-3xl font-semibold text-blueD">
            {t("policy")}
          </h2>
          <p className="mt-2">{t("policytext")}</p>
          <p className="mt-2">{t("policytext2")}</p>
        </div>
      </div>

      {/*------------------------------------*/}
    </section>
  );
}
