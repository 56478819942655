import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";

import NavChart from "./navChart";

import CorrectDate from "../../components/correctDate";

export default function NAV(props) {
  const { t, i18n } = useTranslation("ir", { keyPrefix: "finance.nav" });
  const [data, setData] = useState([]);

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_n;

  const [year, setYear] = useState([]);
  const getYear = async () => {
    const res = await Axios.get(url + "/getYear").then((r) => setYear(r.data));
  };

  useEffect(() => {
    document.title = "DREIT Buy Back - Unit NAV";
    getYear();
  }, []);

  const [selectedYear, setSelectedYear] = useState("last");

  const getData = async () => {
    const res = await Axios.get(url + "/getNAV/" + selectedYear).then((r) => {
      setData(r.data);
    });
  };

  useEffect(() => {
    getData();
  }, [selectedYear]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <section
      id="FAQs"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-5 w-full sm:mb-10 lg:mb-24">
        <h1 className="text-3xl font-semibold text-gold">{t("title")}</h1>
      </div>
      <div className="mb-6 flex items-center gap-2">
        <label htmlFor="selectBox" className="block text-gray-700">
          {t("year")}
        </label>
        <select
          id="selectBox"
          name="selectBox"
          onChange={(e) => setSelectedYear(e.target.value)}
          className="h-8 w-full min-w-fit max-w-[220px] appearance-none rounded-none border-b-2 border-gold/60 px-3 focus:outline-none focus:ring focus:ring-gold"
        >
          <option value="last" selected>
            {t("select0")}
          </option>
          {year.map((y, i) => (
            <option value={y} key={`year-${i}`}>
              {y}
            </option>
          ))}
        </select>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
          />
        </svg>
      </div>
      <div className="flex w-full flex-col justify-center gap-4 lg:flex-row">
        <div className="w-full lg:w-1/2">
          <NavChart data={data} />
        </div>
      </div>
      <div className="p-4">
        <table className="w-full table-fixed border-separate border-spacing-x-2">
          <thead>
            <tr className="font-medium text-gold">
              <th className="border-b-2 border-[#F2E5CF] p-2 text-left">
                {t("header1")}
              </th>
              <th className="border-b-2 border-[#F2E5CF] p-2">
                {t("header2")}
              </th>
              <th className="border-b-2 border-[#F2E5CF] p-2 text-right">
                {t("header3")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr className="border-b-2 border-[#F2E5CF]">
                <td className="p-2">
                  {CorrectDate(d.navDate, "f", "", i18n.language)}
                </td>
                <td className="p-2 text-center">{d.navValue.toFixed(4)}</td>
                <td className="p-2 text-right">
                  {d.netAsset
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
