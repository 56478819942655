import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import * as d3 from "d3";
import { t } from "i18next";

export default function OccChart(props) {
  const [pData, setPdata] = useState([]);

  useEffect(() => {
    setPdata(props.data);
  }, [props.data]);

  const [data, setData] = useState([]);

  const options = {
    title: "Occupency Performance",
    legend: {
      position: "bottom",
    },

    series: {
      0: {
        type: "line",
        targetAxisIndex: 0,
        color: "#bead8f",
      },
      1: { type: "bars", targetAxisIndex: 1, color: "#13216A" },
    },
    vAxes: {
      0: {
        title: "%OCC",
        format: "percent",
        gridlines: { count: 4 },
      },
      1: { title: "ARR" },
    },
    hAxis: {
      title: props.timespan == "all" ? "Year" : "Month",
    },
    vAxis: {
      minorGridlines: { count: 0 },
    },
    height: 400,
    tooltip: {
      isHtml: true,
      trigger: "both",
    },
  };

  const sumData = () => {
    let dat = [
      [
        props.timespan == "all" ? "year" : "month",
        "OCC",
        { type: "string", role: "tooltip", p: { html: true } },
        "ARR",
        { type: "string", role: "tooltip", p: { html: true } },
      ],
    ];
    pData.map((d, i) => {
      dat.push([
        props.timespan == "all" ? d.year.toString() : d.month,
        d.occ,
        "<div style='padding:5px;color:black;'><strong>" +
          (props.timespan == "all" ? d.year : d.month) +
          "</strong></br><p style='display:flex;'>OCC:<strong style='margin-left:4px'>" +
          (d.occ * 100).toFixed(2) +
          "%</strong></p></div>",
        d.arr,
        "<div style='padding:5px;color:black;'><strong>" +
          (props.timespan == "all" ? d.year : d.month) +
          "</strong></br><p style='display:flex;'>ARR:<strong style='margin-left:4px'>" +
          d.arr.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
          "</strong></p></div>",
      ]);
    });
    setData(dat);
  };

  useEffect(() => {
    sumData();
  }, [pData]);

  return (
    <Chart
      chartType="ComboChart"
      data={data}
      options={options}
      width={"100%"}
    />
  );
}
